<template>
  <main>
    <div class="row view-container-receipt">
      <div class="col-12 text-center">
        <div class="image-box">
          <div class="image-box__image">
            <img v-if="!receipt.preview"  src="../../../assets/icons/default_receipt.jpg">
            <img v-if="receipt.preview" v-bind:src="receipt.preview">
            <div class="image-box__image__data">
              <div class="image-data-icon">
                <img v-if="receipt.status == 'processing'" src="../../../assets/icons/receipt_processed.png" >
                <img v-if="receipt.status == 'accepted'" src="../../../assets/icons/receipt_accepted.png">
                <img v-if="receipt.status == 'rejected'" src="../../../assets/icons/receipt_canceled.png">
              </div>
              <div class="image-data-receipt-date">
                {{ receipt.created_at }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p class="receipt-details__status">Paragon zweryfikowany</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p class="receipt-details__title-1">Przyznane punkty: {{ receiptPoints() }}</p>
      </div>

      <div class="col-12">
        <p class="receipt-details__title-2">Dane paragonu:</p>
      </div>

      <div class="col-12 receipt-row" v-if="receipt.shop">
        <p class="receipt-row__label">Nazwa sklepu</p>
        <p class="receipt-row__value">{{ receipt.shop.name }}</p>
      </div>

      <div class="col-12 receipt-row">
        <p class="receipt-row__label">Kwota</p>
        <p class="receipt-row__value">{{ receiptValue() }}</p>
      </div>

      <div class="col-12 receipt-row">
        <p class="receipt-row__label">Data transakcji</p>
        <p class="receipt-row__value">{{ receipt.transaction_date }}</p>
      </div>

      <div class="col-12 receipt-row">
        <p class="receipt-row__label">Numer paragonu</p>
        <p class="receipt-row__value">{{ receipt.receipt_number }}</p>
      </div>

      <div class="col-12 receipt-row">
        <p class="receipt-row__label">Typ paragonu</p>
        <p class="receipt-row__value" v-if="!receipt.tomra">Fiskalny</p>
        <p class="receipt-row__value" v-if="receipt.tomra">Tomra</p>
      </div>
    </div>
  </main>
</template>

<script>
import api from "@/api";

export default {
  name: "Receipt",
  data() {
    return {
      receipt: null
    }
  },
  mounted() {
    api.getReceiptData(this.$route.params.uuid, true).then((response) => {
      this.receipt = response.data;
    });
  },
  methods: {
    receiptPoints() {
      if (this.receipt) {
        let totalValue = parseInt(this.receipt.total_value);

        if (totalValue > 0) {
          return Math.round(totalValue / this.receipt.promotional_event_points_divider);
        }
      }

      return 0;
    },
    receiptValue() {
      if (this.receipt) {
        let totalValue = parseInt(this.receipt.total_value);

        if (totalValue > 0) {
          return totalValue / this.receipt.promotional_event_points_divider + ' zł';
        }
      }

      return '0.00 zł';
    }
  }
}
</script>

<style scoped>
  .view-container-receipt {
    @extends %view-container-receipt;
  }
</style>