<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col">
          <view-title
              :header-class="'section-title'"
              title="Skanowanie paragonu"
          ></view-title>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <receipt-scanner />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ViewTitle from "../../../components/ViewTitle";
import ReceiptScanner from "@/views/authenticated/receipts/ReceiptScanner";
export default {
  name: "ReceiptScanPage",
  components: {
    ViewTitle,
    ReceiptScanner
  }
}
</script>

<style scoped>

</style>
