<template>
  <div>
    <UploadImages
        ref="uploadImages"
        @changed="handleUploadedImages"
        :max="1"
        upload-msg="Kliknij ikonę aby dodać zdjęcie (maksymalny rozmiar pliku 2MiB)."
        style="border:0; height:61%;  border-radius:0"
    />
  </div>
</template>

<script>
import UploadImages from "vue-upload-drop-images";

export default {
  name: "ImageUploader",
  components: {
    UploadImages,
  },
  methods: {
    handleUploadedImages(files) {
      this.loading = true;

      const file = files[0];

      const filesize = file.size/1024;
      const filesizeMiB = ((file.size/1024)/1024).toFixed(2);

      if(filesize > 2048) {
        console.error('File is to large, max 2048');

        this.$emit("filesize", filesizeMiB);
        this.$refs.uploadImages.reset();
        this.loading = false;

        return;
      }

      let fileReader = new FileReader();

      fileReader.onload = e => this.$emit("load", e.target.result);
      fileReader.onerror = e => console.error(e.target.error);

      fileReader.readAsDataURL(file);
    },
  }

}
</script>

<style scoped>

.container {
  background-color: var(--bs-body-bg);
}
</style>
