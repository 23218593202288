<template>
<main>
  <div class="container">
    <div class="row">
      <div v-if="!sendCondeAgain" class="col">
        <h2>Trwa aktywacja konta</h2>
      </div>
      <div v-else>
        <h3>Twój token wygasł. Na dane podane podczas logowania zostanie wysłany nowy link aktywacyjny</h3>
      </div>
    </div>
  </div>
</main>
</template>

<script>
import api from '../../api'
export default {
  name: "RegisterConfirm",
  data: function() {
    return {
      sendCondeAgain: false
    }
  },
  computed: {
    code() {
      return this.$route.params.code
    }
  },
  created() {
    api.verifyAccount(this.code).then((response) => {
      this.$store.commit('SET_USER', response.data)
      this.$router.push('/start')
    }).catch(() => {
      event.preventDefault();
      this.sendCondeAgain = true;

    });
  }
}
</script>

<style scoped>

</style>
