import $store from './store'
// import router from './router'
import axios from "axios";
import {ElNotification} from "element-plus";
import * as Sentry from "@sentry/vue";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true

axios.interceptors.request.use(function(config) {
    $store.commit('LOADING', true)
    return config
}, function(error) {
    return Promise.reject(error)
})
axios.interceptors.response.use(function(response) {
    $store.commit('LOADING', false)
    return response
}, function(error) {
    $store.commit('LOADING', false)
    if(
        error.response.status === 403
        || error.response.status === 419
        || error.response.status === 500
    ) {
        Sentry.captureException(error)
        ElNotification.error({
            title: 'Ups... coś poszło nie tak',
        })
    }
    if(error.response.status === 401) {
        // router.push('/')
    }
    return Promise.reject(error)
})

const API_URL = process.env.VUE_APP_API_URL

export default {
    getCsrf() {
        return axios.get(API_URL + 'sanctum/csrf-cookie')
    },
    getAppConfig() {
        return axios.get(API_URL + 'api/app-config')
    },
    login(loginData) {
        return this.getCsrf()
            .then(() => {
                return axios.post(API_URL + 'api/login', loginData)
                    .then(() => {
                        return this.getLoggedIn()
                    })
            })
    },
    loginUsingGoogle() {
        return axios.post(API_URL + 'api/login/google')
    },
    loginUsingFacebook(token) {
        return this.getCsrf()
            .then(() => {
                return axios.post(API_URL + 'api/login/facebook', { token: token })
            })
    },
    logout() {
        return new Promise((resolve, reject) => {
            axios.post(API_URL + 'api/logout')
                .then(r => {
                    axios.defaults.headers.common['Authorization'] = undefined
                    resolve(r)
                })
                .catch((e) => { reject(e) })
        })
    },
    passwordRecoveryRequest(email, phone_number) {
        return axios.post(API_URL + 'api/password/request', { email: email, phone_number: phone_number })
    },
    passwordRecoveryReset(code, password, password_confirmation) {
        return axios.post(API_URL + 'api/password/reset', { code: code, password: password, password_confirmation: password_confirmation })
    },
    getLoggedIn() {
        return axios.get(API_URL + 'api/customer')
    },
    storeUser(userData) {
        return this.getCsrf()
            .then(() => {
                return axios.post(API_URL + 'api/register', userData)
                    .then(() => {})
            })
    },

    getSlides() {
        return axios.get(API_URL + 'api/slides')
    },

    // BEGIN standard evens (for loyality points)
    getPromotionalEvents() {
        return axios.get(API_URL + 'api/promotional-events');
    },
    getPromotionalRewards() {
        return axios.get(API_URL + 'api/promotional-rewards/list');
    },
    getPromotionalReward(rewardUuid) {
        return axios.post(API_URL + 'api/promotional-reward', {
            reward: rewardUuid
        });
    },
    getCustomerRewards() {
        return axios.get(API_URL + 'api/customer-rewards');
    },
    getPromotionalRewardsReservations() {
        return axios.get(API_URL + 'api/promotional-rewards-issue/list');
    },
    cancelPromotionalRewardReservation(rewardUuid) {
        return axios.get(API_URL + 'api/promotional-rewards-issue/cancel/' + rewardUuid);
    },
    promotionalRewardReservation(rewardUuid, promotionalEventUuid) {
        return axios.post(API_URL + 'api/promotional-rewards-issue/reservation', {
            reward: rewardUuid,
            promotional_event: promotionalEventUuid
        });
    },
    // END standard events (for loyality points)


    //BEGIN promotional events (bottles events)
    getOffers() {
        return axios.get(API_URL + 'api/offers')
    },
    getAllRewardsForPoints() {
        return axios.get(API_URL + 'api/reward-for-points');
    },
    getRewardsForPointsReservations() {
        return axios.get(API_URL + 'api/reward-for-points-issues/list');
    },
    rewardForPointsReservation(rewardUuid, customerUuid) {
        return axios.post(API_URL + 'api/reward-for-points-issues/reserve', {
            rewards_for_points: rewardUuid,
            customer: customerUuid
        });
    },
    // END promotional events (bottles events)

    getPage(uuid) {
        return axios.get(API_URL + 'api/pages/'+uuid)
    },
    storeIssue(title, description) {
        return axios.post(API_URL + 'api/issues', {
            title: title,
            description: description,
        })
    },
    getIssues() {
        return axios.get(API_URL + 'api/issues')
    },
    replyToIssue(uuid, body) {
        return axios.post(API_URL + 'api/issues/' + uuid + '/reply', { body: body })
    },
    getUnreadIssuesCount() {
        return axios.get(API_URL + 'api/issues/unread-count');
    },
    markIssueAsRead(uuid) {
        return axios.get(API_URL + 'api/issues/' + uuid + '/mark-as-read');
    },
    getNotifications(nextPageUrl = null) {
        return axios.get(nextPageUrl ? nextPageUrl : (API_URL + 'api/notifications'))
    },
    getNotification(uuid) {
        return axios.get(API_URL + 'api/notifications/'+uuid)
    },
    getUnreadNotifications() {
        return axios.get(API_URL + 'api/notifications-unread')
    },
    markAsReadNotifications() {
        return axios.get(API_URL + 'api/notifications-mark-as-read')
    },
    markAsReadNotification(uuid) {
        return axios.get(API_URL + 'api/notifications-mark-as-read/'+uuid)
    },

    updatePersonalData(personalData) {
        return axios.patch(API_URL + 'api/customer', personalData)
    },
    updatePassword(currentPassword, newPassword, newPasswordConfirmation)
    {
        return axios.patch(API_URL + 'api/customer/password', {
            password_current: currentPassword,
            password: newPassword,
            password_confirmation: newPasswordConfirmation,
        })
    },
    updateAgreements(agreements)
    {
        return axios.patch(API_URL + 'api/customer/agreements', agreements)
    },
    updateScannerAgreements(agreements)
    {
        return axios.patch(API_URL + 'api/customer/scanner-agreements', agreements)
    },
    requestEmailVerification()
    {
        return axios.post(API_URL + 'api/resend-email-verification-message')
    },
    requestPhoneNumberVerification()
    {
        return axios.post(API_URL + 'api/resend-phone-number-verification-message')
    },
    verifyEmail(code)
    {
        return axios.post(API_URL + 'api/verify-email', {'authorization_code': code})
    },
    verifyAccount(code)
    {
        return axios.post(API_URL + 'api/verify-account', {'authorization_code': code})
    },
    verifyPhoneNumber(code)
    {
        return axios.post(API_URL + 'api/verify-phone-number', {'authorization_code': code})
    },
    sendBarCode(barcode, userId)
    {
        return axios.post(API_URL + 'api/send-barcode', {barcode: barcode, user_id: userId})
    },
    getShops()
    {
        return axios.get(API_URL + 'api/shops')
    },
    storeReceipt(shopUuid, receiptImageData) {
        return axios.post(API_URL + 'api/store-receipt', {
            shop_uuid: shopUuid,
            image_data: receiptImageData,
        })
    },
    userReceipts(data)
    {
        return axios.get(API_URL + 'api/receipts', {params: data})
    },
    getReceiptData(receiptUuid, withShopData) {
        return axios.get(API_URL + 'api/receipts/' + receiptUuid + (withShopData ? '/1' : ''));
    },
    userHistoryPoints()
    {
        return axios.get(API_URL + 'api/customer/history-points')
    }
}
