
<template>
  <main class="issue-details">
    <div class="container" v-if="issue">
      <div class="row">
        <div class="col">
          <h5>{{issue.title}}</h5>
          <hr/>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div v-for="(m, index) in issue.messages" :key="index" class="issue-message"
               :class="{'me': m.author_type.includes('Customer')}">
            <div class="content">
              <p>{{ m.body }}</p>
            </div>
            <span v-if="m.created_at">{{ new Date(m.created_at).toLocaleDateString() }}</span>
          </div>
        </div>
      </div>
      <div v-loading="loading" class="row" v-if="issue.is_open">
        <div class="col">
          <div class="chat-type-message">
            <el-input placeholder="Aa" type="text" v-model="newMessage"></el-input>
            <button class="btn btn-primary" @click.prevent="submitNewMessage"><i class="icon-send"></i></button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import api from "../../../../api";

export default {
  name: "Issue",
  data(){
    return {
      newMessage: null,
      loading: false,
    }
  },
  computed: {
    issue() {
      return this.$store.state.issues.find(i => i.uuid === this.$route.params.uuid)
    }
  },
  mounted() {
    if (this.issue.is_unread) {
      this.markAsRead();
    }
  },
  methods: {
    submitNewMessage(){
      this.loading = true
      api.replyToIssue(this.$route.params.uuid, this.newMessage)
        .then(r => {
          this.$store.commit('UPDATE_ISSUE', r.data)
          this.newMessage = null
        })
        .finally(() => { this.loading = false })
    },
    markAsRead() {
      api.markIssueAsRead(this.$route.params.uuid).then(() => {
        this.$store.state.unreadIssues--;
        this.issue.is_unread = false;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.issue-details{
  @extend %issue-details !optional;
}
.chat-type-message{
  @extend %chat-type-message !optional;
}
</style>