<template>
  <div v-if="pointsVisible" class="customer-points-container">
    <template v-if="$store.state.config.features.receiptScan">
      <div class="customer-points">
        <div class="customer-points__row">
          <div class="customer-points__logo">
            <img :src="$theme.media.logoLight" alt=""/>
          </div>
          <div class="customer-points__informations">
            <div class="row">
              <div class="col" v-text="$dictionary.customer_points_label_short"></div>
            </div>
            <div class="row">
              <div class="col">
                <span class="customer-points__count">
                  {{
                    $store.state.loggedUser && $store.state.loggedUser.loyalty_points ? $store.state.loggedUser.loyalty_points.available : 0
                  }} <small>pkt</small>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="customer-points__row">
          <div class="col text-center">
            <router-link
                to="/rewards"
                class="btn btn-dark mt-4"
            >
              <i class="icon-prize" style="font-size: 32px; margin-right: 4px;"></i>
              <span v-text="$dictionary.show_rewards_catalogue"></span>
            </router-link>
          </div>
        </div>
        <div class="customer-points__row">
          <div class="col text-center">
            <router-link
                to="/rewards/collected"
                class="btn btn-dark mt-4"
            >
              <i class="icon-gift" style="font-size: 32px; margin-right: 4px;"></i>
              <span v-text="$dictionary.show_awards"></span>
            </router-link>
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.state.config.features.rewardsForPoints && $theme.media.ecoSlider && $theme.media.ecoAdv">
      <div class="account-info-mobile-container">
        <a href="https://loyalty.posnania.eu/events/1e7e0708-5f4e-428a-a389-09769b96180a"><img
            :src="$theme.media.ecoSlider" width="200px" alt=""/></a>
      </div>
      <div class="account-info-mobile-container">
        <a href="https://loyalty.posnania.eu/events/1e7e0708-5f4e-428a-a389-09769b96180a"><img
            :src="$theme.media.ecoAdv" width="200px" alt=""/></a>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "CustomerPointsMobile",
  computed: {
    pointsVisible() {
      let promotionalEvents = this.$store?.state?.config?.promotionalEvents;

      if (promotionalEvents && promotionalEvents.length > 0) {
        for (let promotionalEvent of promotionalEvents) {
          if (promotionalEvent.eventType === 'loyalty_points') {
            return true;
          }
        }
      }

      return false;
    }
  }
}
</script>

<style scoped lang="scss">
.customer-points-container {
  @extend %account-info-mobile-container;
  @extend %account-info-customer-points;
}
</style>

