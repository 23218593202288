<template>
  <div>
    <template v-if="$theme.media.ecoSlider && $theme.media.ecoAdv">
      <div class="account-info-mobile-container">
        <a href="https://loyalty.posnania.eu/events/1e7e0708-5f4e-428a-a389-09769b96180a"><img :src="$theme.media.ecoSlider" alt=""/></a>
      </div>
      <div class="account-info-mobile-container">
        <a href="https://loyalty.posnania.eu/events/1e7e0708-5f4e-428a-a389-09769b96180a"><img :src="$theme.media.ecoAdv" alt=""/></a>
        <!--    <router-link to="/rewards/collected" class="btn btn-light mt-4"><i class="icon-gift"></i><span>Pokaż moje nagrody</span></router-link>-->
      </div>
    </template>
    <template v-else>
      <div class="account-info-mobile-container-default">
        <div class="account-info-mobile-info-container">
          <img :src="$theme.media.logoLight" alt=""/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "AccountInfoMobile",
}
</script>

<style scoped lang="scss">
.account-info-mobile-container-default {
  @extend %account-info-mobile-container;
}

.account-info-mobile-container {
  background: transparent;
  padding: 1rem !important;

  a img {
    width: 100%
  }

  @extend %account-info-mobile-container;
}
.account-info-mobile-points-container {
  @extend %account-info-mobile-points-container;
}
</style>

