<template>
  <div class="event-item-container position-relative">
    <div class="overlay"></div>
    <img :src="image ? image : $theme.media.placeholderEvent"/>
    <h3>{{ name }}</h3>
    <listing-item-labels v-if="time" :labels="[time]"></listing-item-labels>
  </div>
</template>

<script>
import ListingItemLabels from "./ListingItemLabels";
export default {
  name: "EventListingItem",
  components: {ListingItemLabels},
  props: {
    image: String,
    name: String,
    time: String,
  }
}
</script>

<style scoped lang="scss">
.event-item-container {
  @extend %event-item-container;
}
</style>
