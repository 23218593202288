<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col">
          <view-title back-location="/" :title="$dictionary.form_authorization_password_reset_heading"></view-title>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <password-reset-form/>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import PasswordResetForm from "@/components/forms/PasswordResetForm";
import ViewTitle from "@/components/ViewTitle";
export default {
  name: "PasswordReset",
  components: {ViewTitle, PasswordResetForm}
}
</script>

<style scoped>

</style>