<template>
  <div class="modal" :class="visibility ? 'show' : null">
    <div class="modal-wrapper">
      <div class="modal-animation">
        <button v-if="close" @click="$emit('close')" class="modal-close"></button>
        <div class="modal-container">
          <slot></slot>
        </div>
      </div>
    </div>
    <div @click="$emit('close')" class="modal-overlay"></div>
  </div>
</template>

<script>
export default {
  props: {
    visibility: {
      type: Boolean,
      default: false
    },
    close: {
      type: Boolean,
      default: false
    },
    hideCallback: {
      type: Function,
      default: () => {}
    }
  },
  watch: {
    visibility: function(newVisibility) {
      if(newVisibility){
        document.body.style.overflow = 'hidden'
        document.body.style.position = 'relative'
        return
      }
      document.body.style.overflow = null
      document.body.style.position = null
    }
  }
}
</script>
<style lang="scss">
.modal {
  @extend %modal
}
</style>