<template>
    <div
        class="center-offer"
        :class="{ 'col mb-4 mt-2': cols }"
        v-for="(item, index) in offers"
        :key="index"
        :image="item?.thumbnail"
    >
      <offer-listing-item
          :image="item?.thumbnail ? item.thumbnail : item.thumbnail_url"
          :brand-image="item?.brand_thumbnail ? item.brand_thumbnail : (item?.partner ? item.partner.thumbnail_url : null)"
          :title="item?.name"
          :category="item?.category"
          :discount="item?.discount_config"
          :offer-time="item?.time"
          :overlay="overlay"
          @click="activeOfferIndex = index+1"
      />
    </div>

  <modal :visibility="!!activeOfferIndex"
         v-if="activeOffer"
         @close="hideModal"
         :hideCallback="hideModal"
         close
         class="offer"
  >
    <div class="row offer-in-modal">
      <div class="col-12 col-md-6 text-center">
        <offer-listing-item
            :image="activeOffer.thumbnail ? activeOffer.thumbnail : activeOffer.thumbnail_url"
            :brand-image="activeOffer.brand_thumbnail"
            :discount="activeOffer.discount_config"
            :title="activeOffer.name"
            :offer-time="activeOffer.time"
            brand-position="top"
            disable-title
            modal-mode
            :overlay="false"
            :full-width="true"
        />
      </div>
      <div class="col-12 col-md-6 modal-container-body">
        <h5 class="text-blue">{{activeOffer.name}}</h5>
        <div v-html="activeOffer.description"></div>
        <div v-if="activeOffer.category">
          <hr/>
          <p><strong v-html="activeOffer.category"></strong></p>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/utilities/Modal";
import OfferListingItem from "@/components/listing-items/OfferListingItem";

export default {
  name: 'OfferListing',
  components: { Modal, OfferListingItem },
  props: {
    offers: Array,
    dashboardMode: {
      type: Boolean,
      default: false
    },
    overlay: {
      type: Boolean,
      default: true
    },
    cols: {
     type: Boolean,
     default: true,
    }
  },
  data(){
    return {
      activeOfferIndex: null,
    }
  },
  computed: {
    activeOffer(){
      if(this.activeOfferIndex) return this.offers[this.activeOfferIndex-1]
      else return false
    }
  },
  methods: {
    hideModal() {
      this.activeOfferIndex = null
    }
  },
}
</script>

<style scoped lang="scss">
</style>
