<template>
  <main>
    <div class="container notifications-page-container">
      <div class="row">
        <div class="col">
          <view-title
              :header-class="'section-title'"
              back-location="/notifications"
              :title="$dictionary.notification_view_title"
          ></view-title>
        </div>
      </div>

      <template v-if="notification">
        <div class="row">
          <div class="col">

            <div class="sent text-end">
              <span>{{ notification.sent }}</span>
            </div>

            <h1 class="mb-4">{{ notification.title }}</h1>
            <p class="message">{{ notification.message }}</p>
          </div>
        </div>

        <div class="row text-center mt-4" v-if="notification.image_url">
          <div class="col">
            <img :src="notification.image_url" class="img-fluid" alt="">
          </div>
        </div>

        <div class="row text-center mt-4" v-if="notification.button_label && notification.button_url">
          <div class="col">
            <button class="btn btn-dark" @click="onClick(notification.button_url)">{{  notification.button_label }}</button>
          </div>
        </div>
      </template>

    </div>
  </main>
</template>

<script>
import ViewTitle from "@/components/ViewTitle";
import api from "@/api";

export default {
  name: "NotificationPage",
  components: {
    ViewTitle
  },
  data() {
    return {
      notification: null
    }
  },
  created() {
    this.getNotification();
  },
  methods: {
    getNotification() {
      api.getNotification(this.$route.params.uuid)
          .then(async response => {
            if (response.status === 200) {
              this.notification = response.data;

              if (this.notification.read_at === null) {
                await api.markAsReadNotification(this.$route.params.uuid);
                this.$store.dispatch('loadUnreadNotifications');
              }

            }
          })
    },
    onClick(url) {
      const external = new RegExp('^https?:\\/\\/(.*)', 'gm');
      if(external.test(url)) {
        window.location.href = url;
      }

      const internal = new RegExp('^\\/(.*)', 'gm')
      if(internal.test(url)) {
        this.$router.push(url);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.notifications-page-container {
  @extend %notifications-page-container;
}
</style>
