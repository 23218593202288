import api from "../api";

const logout = {
    methods: {
        logout(e) {
            e.preventDefault()
            this.$store.commit('FS_LOADING', true)
            api.logout()
                .then(() => {
                    this.$store.commit('SET_USER', null)
                    this.$router.push('/')
                })
                .finally(() => {
                    this.$store.commit('FS_LOADING', false)
                })
        }
    }
}

const preprocessor = {
    methods: {
        findMentions(text) {
            const regex = /\[.*?\]([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})/gm
            let mentions = text.match(regex)
            return mentions ? mentions : []
        },
        getMentionContent(mention) {
            return mention.slice(0, -37).substring(1)
        },
        getMentionSrc(mention) {
            return mention.substring(mention.length - 36)
        },
        __(text) {
            this.findMentions(text).forEach(m => {
                text = text.replace(m, '<a href="/page/' + this.getMentionSrc(m) + '"  target="_blank">' + this.getMentionContent(m) + '</a>')
            })
            return text;
        },
        _n(text) {
            this.findMentions(text).forEach(m => {
                text = text.replace(m, this.getMentionContent(m))
            })
            return text;
        }
    }
}

const hasOsInfo = {
    methods: {
        getOs() {
            var userAgent = window.navigator.userAgent,
                platform = window.navigator.platform,
                macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                iosPlatforms = ['iPhone', 'iPad', 'iPod'],
                os = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'os_mac';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'os_ios';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'os_windows';
            } else if (/Android/.test(userAgent)) {
                os = 'os_android';
            } else if (!os && /Linux/.test(platform)) {
                os = 'os_linux';
            }

            return os;
        },
        isAppleOs() {
            let os = this.getOs()
            return os === 'os_mac' || os === 'os_ios';
        }
    }
}

export {
    logout,
    preprocessor,
    hasOsInfo,
}
