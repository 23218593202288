<template>
  <main>
    <password-change-form></password-change-form>
  </main>
</template>

<script>
import PasswordChangeForm from "../../../components/forms/PasswordChangeForm";
export default {
  name: "Password",
  components: {PasswordChangeForm}
}
</script>

<style scoped>

</style>