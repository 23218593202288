<template>
  <div v-if="cfg.fields" v-loading="loading" class="row">
    <div v-if="!registered">
    <p v-if="social.type === 'facebook'">Uzupełnij poniższe dane</p>

    <div v-if="cfg.fields.first_name.enabled" class="col-12 mb-3">
      <el-input v-model="register_form.first_name"
                prefix-icon="icon-user"
                :placeholder="getTranslationsMix($dictionary.form_register_first_name_placeholder) + (cfg.fields.first_name.required ? ' *' : '')">
      </el-input>
      <form-control-errors :error-bag="errors" field="first_name"></form-control-errors>
    </div>

    <div v-if="cfg.fields.last_name.enabled" class="col-12 mb-3">
      <el-input v-model="register_form.last_name"
                prefix-icon="icon-user"
                :placeholder="getTranslationsMix($dictionary.form_register_last_name_placeholder) + (cfg.fields.last_name.required ? ' *' : '')">
      </el-input>
      <form-control-errors :error-bag="errors" field="last_name"></form-control-errors>
    </div>

    <div v-if="cfg.fields.email.enabled" class="col-12 mb-3">
      <el-input v-model="register_form.email"
                type="email"
                prefix-icon="icon-mail"
                :placeholder="getTranslationsMix($dictionary.form_register_email_placeholder) + (cfg.fields.email.required ? ' *' : '')">
      </el-input>
      <form-control-errors :error-bag="errors" field="email"></form-control-errors>
    </div>

    <div v-if="cfg.fields.phone_number.enabled" class="col-12 mb-3">
      <el-input v-model="register_form.phone_number"
                prefix-icon="icon-phone"
                :placeholder="getTranslationsMix($dictionary.form_register_phone_number_placeholder) + (cfg.fields.phone_number.required ? ' *' : '')">
      </el-input>
      <form-control-errors :error-bag="errors" field="phone_number"></form-control-errors>
    </div>

    <div v-if="cfg.fields.username.enabled" class="col-12 mb-3">
      <el-input v-model="register_form.username"
                prefix-icon="icon-user"
                :placeholder="getTranslationsMix($dictionary.form_register_username_placeholder) + (cfg.fields.username.required ? ' *' : '')">
      </el-input>
      <form-control-errors :error-bag="errors" field="username"></form-control-errors>
    </div>

    <div class="col-12 mb-3">
      <el-input v-model="register_form.password"
                prefix-icon="icon-lock"
                type="password"
                :minlength="8"
                :min="8"
                :placeholder="getTranslationsMix($dictionary.form_register_password_placeholder) + ' *'">
      </el-input>
      <form-control-errors :error-bag="errors" field="password"></form-control-errors>
    </div>

    <div v-if="cfg.fields.birthday.enabled" class="col-12 mb-3">
      <el-date-picker v-model="register_form.birthday"
                      style="width: 100%"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                      prefix-icon="icon-calendar"
                      :placeholder="getTranslationsMix($dictionary.form_register_birthday_placeholder) + (cfg.fields.birthday.required ? ' *' : '')">
      </el-date-picker>
      <form-control-errors :error-bag="errors" field="birthday"></form-control-errors>
    </div>

    <div v-if="cfg.fields.gender.enabled" class="col-12 mb-3">
      <el-select v-model="register_form.gender"
                 style="width: 100%"
                 :placeholder="getTranslationsMix($dictionary.form_register_gender_placeholder) + (cfg.fields.gender.required ? ' *' : '')">
        <el-option :label="$dictionary.form_register_gender_option_male" value="male"></el-option>
        <el-option :label="$dictionary.form_register_gender_option_female" value="female"></el-option>
        <el-option v-if="!cfg.fields.gender.required" :label="$dictionary.form_register_gender_option_null" value=""></el-option>
        <template v-slot:prefix><i class="el-input__icon icon-user"></i></template>
      </el-select>
      <form-control-errors :error-bag="errors" field="gender"></form-control-errors>
    </div>

    <div v-if="cfg.fields.postal_code.enabled" class="col-12 mb-3">
      <el-input v-model="register_form.postal_code"
                v-maska="'##-###'"
                prefix-icon="icon-pin"
                :placeholder="getTranslationsMix($dictionary.form_register_postal_code_placeholder) + (cfg.fields.postal_code.required ? ' *' : '')">
      </el-input>
      <form-control-errors :error-bag="errors" field="postal_code"></form-control-errors>
    </div>

    <div v-if="availableAgreements.length > 0" class="col-12 mb-3 mt-4">
      <agreement-set v-model:agreements="register_form.agreements"
                     :errors="errors"
                     :available-agreements="availableAgreements"></agreement-set>
    </div>

    <div class="col-12 mt-4">
      <div class="d-grid gap-3">
        <button @click="handleFormSubmit" class="btn btn-dark">{{ getTranslationsMix($dictionary.form_register_submit_button_label) }}</button>
      </div>
    </div>
    </div>
    <div v-else>
      <div class="row"><h2>Dziękujemy za rejestrację</h2></div>
      <div class="row">Na Twój adres-email lub numer telefonu przyjdzie kod wraz z linkiem do aktywacji konta.</div>
    </div>
  </div>
</template>

<script>
import AgreementSet from "../AgreementSet";
import api from '../../api'
import FormControlErrors from "../FormControlErrors";

export default {
  name: "RegisterForm",
  components: {FormControlErrors, AgreementSet},
  data() {
    return {
      register_form: {
        email: null,
        phone_number: null,
        password: null,
        first_name: null,
        last_name: null,
        birthday: null,
        postal_code: null,
        gender: null,
        device_name: 'customer_device',
        agreements: null,
      },
      social: {
        type: null,
        token: null,
      },
      errors: {},
      loading: false,
      registered:false
    }
  },
  computed: {
    cfg() {
      if(!this.$store.state.config.customerRegisterConfig) return {}
      return this.$store.state.config.customerRegisterConfig
    },
    availableAgreements() {
      if(!this.cfg) return []
      return this.cfg.register_agreements
    }
  },
  created() {
    if(this.$route.params.social) {
      this.loadDataFromSocial()
    }
  },
  methods: {
    handleFormSubmit() {
      this.loading = true
      const that = this;
      api.storeUser(this.register_form)
        .then(() => {
          if (process.env.VUE_APP_REGISTER_CONFIRM_ENABLED == 1) {
            that.registered = true;
            that.loading = false
          } else {
            window.location.reload();
          }
        })
        .catch(error => {
          if(error.response.status === 422) that.errors = error.response.data.errors
          that.loading = false
        })
    },
    loadDataFromSocial() {
      this.social.type = this.$route.params.social
      this.social.token = this.$route.params.token
    }
  }
}
</script>

<style scoped>

</style>
