<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col">
          <view-title
              :header-class="'section-title'"
              :title="$dictionary.event_catalogue_view_title"
          ></view-title>
        </div>
      </div>
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 mt-5 mt-lg-0">
        <div class="col mb-4" v-for="(item, index) in $store.state.events" :key="index">
          <event-listing-item @click="$router.push({ name: 'Event', params: { uuid: item.uuid }})" :image="item.thumbnail_url" :time="item.time" :name="item.name"></event-listing-item>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ViewTitle from "../../../components/ViewTitle";
import EventListingItem from "../../../components/listing-items/EventListingItem";
export default {
  name: "EventsCatalogue",
  components: {EventListingItem, ViewTitle},
  created() {
    this.$store.dispatch('loadPromotionalEvents')
  }
}
</script>

<style scoped>

</style>