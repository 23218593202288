<template>
  <div class="carousel-container carousel-inner" ref="container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CarouselContainer",
  props: {
    scroll: Number,
  },
  watch: {
    scroll(nv) {
      this.$refs.container.scroll(nv, 0)
    },
  },
}
</script>

<style scoped lang="scss">
.carousel-container {
  @extend %carousel-container;
}
</style>