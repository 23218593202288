<template>
  <div v-loading="loading">
    <div class="col-12 mb-3">
      <el-input v-model="email"
                type="email"
                prefix-icon="icon-mail"
                :placeholder="getTranslationsMix($dictionary.form_login_email_placeholder) + ' *'">
      </el-input>
      <form-control-errors :error-bag="errors" field="email"></form-control-errors>
    </div>

    <div class="col-12 mb-3">
      <el-input v-model="phone_number"
                type="text"
                prefix-icon="icon-phone"
                :placeholder="getTranslationsMix($dictionary.form_login_phone_number_placeholder)">
      </el-input>
      <form-control-errors :error-bag="errors" field="phone_number"></form-control-errors>
    </div>

    <div class="col-12 mb-3">
      <div class="col-12 mb-3">
        <el-input v-model="password"
                  prefix-icon="icon-lock"
                  type="password"
                  show-password
                  :placeholder="getTranslationsMix($dictionary.form_login_password_placeholder)">
        </el-input>
        <form-control-errors :error-bag="errors" field="password"></form-control-errors>
      </div>
    </div>

    <div class="col-12 mt-4 login-form-rember-reset-container">
      <div class="row">
        <div class="col">
          <div class="form-check" style="margin-bottom: 0">
            <input v-model="remember" class="form-check-input" type="checkbox">
            <div class="label_container">
              <label class="form-check-label">
                <div>{{ getTranslationsMix($dictionary.form_authorization_remember_me_label) }}</div>
              </label>
            </div>
          </div>
        </div>
        <div class="col-auto text-end border-start">
          <router-link to="/password/reset">{{ getTranslationsMix($dictionary.form_authorization_password_reset_button_label) }}</router-link>
        </div>
      </div>
    </div>

    <div class="col-12 mt-3">
      <div class="d-grid gap-3">
        <button @click="handleFormSubmit" class="btn btn-dark">{{ getTranslationsMix($dictionary.form_login_submit_button_label) }}</button>
      </div>
    </div>

    <div class="col-12 mt-4">
      <div class="d-flex align-items-stretch gap-3">
        <button v-if="$store.state.config.features.facebookLogin" @click="loginUsingFacebook" class="btn btn-social-media facebook"><img src="../../assets/icons/facebook.svg" alt="Facebook"></button>
        <button v-if="$store.state.config.features.googleLogin"  @click="useAuthProvider('google')" class="btn btn-social-media google"><img src="../../assets/icons/google.svg" alt="Google"></button>
      </div>
    </div>
  </div>
</template>

<script>
import FormControlErrors from "../FormControlErrors";
import api from '../../api';
import { Providers} from 'universal-social-auth'
export default {
  name: "LoginForm",
  components: {FormControlErrors},
  data() {
    return {
      email: null,
      phone_number: null,
      password: null,
      remember: false,
      errors: {},
      loading: false,
    }
  },
  created() {
    if(this.$route.name === 'SocialLoginCallback') {
     // let search = new URL(window.location.href).search
      // api.loginUsingFacebookCallback(search)
      //   .then(r => {
      //     console.log(r)
      //   })
    }
  },
  methods: {
    handleFormSubmit() {
      this.loading = true
      api.login({email: this.email, phone_number: this.phone_number, password: this.password, device_name: 'customer_device'})
        .then(response => {
          this.$store.commit('SET_USER', response.data)
          this.$router.push('/start')
        })
        .catch(error => {
          if(error.response.status === 422) this.errors = error.response.data.errors
        })
        .finally(() => { this.loading = false })
    },
    useAuthProvider (provider) {
      const self = this;
      let pro = Providers['google'];
      pro['autoclose'] = true;
      pro['container'] = 'app';
      console.log(pro)
      let responseData = {
        value: {
          code: null,
          provider: provider
        }
      }
      this.$Oauth.authenticate(provider, pro).then((response) => {
        const rsp = response
        if (rsp.code) {
          responseData.value.code = rsp.code
          responseData.value.provider = provider
          self.loginUsingGoogle(responseData)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
     loginUsingGoogle(responseData) {
       const self = this
       api.loginUsingGoogle(responseData)
           .then(response => {
             console.log(response);
             window.close();
             window.location.reload();
           })
           .finally(() => {
             self.loading = false
             this.$router.push('/start')
             window.close();
             window.location.reload();
           })
    },
    loginUsingFacebook() {
      this.loading = true
      window.FB.login((response) => {
        if(response.authResponse) {
          let accessToken = response.authResponse['accessToken']
          api.loginUsingFacebook(accessToken)
          .then(() => {
            window.location.reload()
          })
          .catch(e => {
            if(e.response.status === 303) {
              let data = e.response.data
              this.$router.push({ name: 'Register', params: { social: 'facebook', token: data.token, email: data.user.email } })
            }
          })
          .finally(() => { this.loading = false })
        } else {
          console.log(":(")
        }
      })


      // api.loginUsingFacebook()
      //   .then(response => {
      //     window.open(response.data)
      //   })
      //   .finally(() => { this.loading = false })
    }
  }
}
</script>

<style scoped lang="scss">
.login-form-rember-reset-container {
  @extend %login-form-remember-reset-container;
}
</style>
