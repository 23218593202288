<template>
  <div v-loading="loading">
    <div v-if="form === 'reset'" class="row">
      <div class="col-12 mb-4">
        <p>{{ $dictionary.form_password_reset_logout_info_alert }}</p>
      </div>

      <div class="col-12 mb-3">
        <el-input v-model="auth_code"
                  type="text"
                  :placeholder="$dictionary.form_password_reset_authorization_code_placeholder">
        </el-input>
        <form-control-errors :error-bag="errors" field="code"></form-control-errors>
      </div>

      <div class="col-12 mb-3">
        <el-input v-model="password"
                  type="password"
                  show-password
                  :placeholder="$dictionary.form_password_reset_password_placeholder">
        </el-input>
        <form-control-errors :error-bag="errors" field="password"></form-control-errors>
      </div>

      <div class="col-12 mb-3">
        <el-input v-model="password_repeat"
                  type="password"
                  show-password
                  :placeholder="$dictionary.form_password_reset_password_repeat_placeholder">
        </el-input>
        <form-control-errors :error-bag="errors" field="password_repeat"></form-control-errors>
      </div>

      <div class="col-12 mt-3">
        <div class="d-grid gap-3">
          <button class="btn btn-dark" @click="handlePasswordReset">{{ $dictionary.form_password_reset_submit_button_label }}</button>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-12 mb-3">
        <div class="col-12 mb-4">
          <p>{{ $dictionary.form_password_reset_authorization_code_info_alert }}</p>
        </div>
        <el-input v-model="email"
                  type="email"
                  :placeholder="$dictionary.form_password_reset_email_placeholder">
        </el-input>
        <el-input v-model="phone_number"
                  type="text"
                  :placeholder="$dictionary.form_password_reset_phone_number_placeholder">
        </el-input>
        <form-control-errors :error-bag="errors" field="email"></form-control-errors>
      </div>
      <div class="col-12 mt-3">
        <div class="d-grid gap-3">
          <button class="btn btn-dark" @click="handleAuthCodeRequest()">{{ $dictionary.form_password_reset_authorization_code_submit_button_label }}</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-3">
        <div class="d-grid gap-3">
          <button class="btn" @click="form = form === 'reset' ? 'get-reset-code' : 'reset' ">
            {{form === 'reset' ? $dictionary.form_password_reset_do_not_have_code_info : $dictionary.form_password_reset_do_have_code_info }}
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-3">
        <div class="d-grid gap-3">
          <router-link :to="{ name: 'Home' }" class="btn btn-outline-dark">{{ $dictionary.form_password_reset_return_button_label }}</router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import FormControlErrors from "@/components/FormControlErrors";
import api from "../../api";
import {ElNotification} from "element-plus";

export default {
  name: "PasswordResetForm",
  components: {FormControlErrors},
  data() {
    return {
      auth_code: null,
      password: null,
      password_repeat: null,
      email: null,
      phone_number: null,
      errors: {},
      loading: false,
      form: 'get-reset-code',
    }
  },
  methods: {
    handlePasswordReset() {
      this.loading = true
      api.passwordRecoveryReset(this.auth_code, this.password, this.password_repeat)
        .then(() => {
          ElNotification.success({
            title: this.$dictionary.form_password_reset_password_changed_success_message,
          })
          setTimeout(function() {
            window.location.reload()
          }, 1000)
        })
        .catch(e => {
          if(e.response.status === 422) this.errors = e.response.data.errors
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleAuthCodeRequest(){
      this.loading = true
      api.passwordRecoveryRequest(this.email, this.phone_number)
        .then(() => {
          this.form = 'reset'
          ElNotification.success({
            title: this.$dictionary.form_password_reset_code_sent_success_message,
          })
        })
        .catch(e => {
          if(e.response.status === 422) this.errors = e.response.data.errors
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style scoped>

</style>
