<template>
  <div class="home-auth-forms-container">
    <div class="row mb-4">
      <div class="col">
        <h2>{{ form === 'register' ? getTranslationsMix($dictionary.form_authorization_register_heading) : (form === 'login' ? getTranslationsMix($dictionary.form_authorization_login_heading) : getTranslation($dictionary.form_authorization_password_reset_heading)) }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <login-form v-if="form === 'login'"></login-form>
        <register-form v-if="form === 'register'"></register-form>
        <password-reset-form v-if="form === 'password-reset'"></password-reset-form>
      </div>
    </div>
    <div class="row secondary-action-container">
      <div class="col text-center">
        <button class="btn btn-outline-dark mb-3" v-if="form !== 'login'" @click="form = 'login'">{{ getTranslationsMix($dictionary.form_authorization_login_button_label) }}</button>
        <button class="btn btn-outline-dark mb-3" v-else @click="form = 'register'">{{ getTranslationsMix($dictionary.form_authorization_register_button_label) }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "./forms/LoginForm";
import RegisterForm from "./forms/RegisterForm";
import PasswordResetForm from "@/components/forms/PasswordResetForm";
export default {
  name: "auth-forms-container",
  components: {RegisterForm, LoginForm, PasswordResetForm},
  data() {
    return {
      form: 'login',
    }
  },
}
</script>

<style scoped lang="scss">
.home-auth-forms-container {
  @extend %home-auth-forms-container;
}
</style>
