import { createStore } from 'vuex'
import api from '../api'

export default createStore({
  state: {
    loading: false,
    fullscreenLoading: false,
    loadingInfo: null,
    config: {},
    loggedIn: false,
    loggedUser: null,
    deferredPrompt: null,

    events: [],
    issues: [],

    unreadNotifications: 0,
    unreadIssues: 0
  },
  getters: {
    featuredOffers: state => {
      return state.config.featuredOffers
    },
    featuredRewards: state => {
      return state.config.featuredRewards
    },
    unreadNotifications: state => {
      return state.unreadNotifications
    },
    mediaHomeHeader: state => {
      const homeHeader = state.config.media['home_header'];

      if(homeHeader) {
        return homeHeader;
      }

      return null;
    }
  },
  mutations: {
    SET_CONFIG(state, config) {
      state.config = {...config};
    },
    FS_LOADING(state, loading) {
      state.fullscreenLoading = loading
    },
    LOADING(state, loading) {
      if(typeof loading === 'string') {
        state.loadingInfo = loading
        loading = true;
      }
      state.loading = loading
      state.loadingInfo = null
    },
    SET_USER(state, user) {
      state.loggedUser = user
      state.loggedIn = user === null ? false : true;
    },
    SET_ISSUES(state, issues) {
      state.issues = issues
    },
    UPDATE_ISSUE(state, issue) {
      state.issues = [...state.issues.filter(i => i.uuid !== issue.uuid), issue]
    },
    SET_USER_POINTS(state, points) {
      if (state.loggedUser) {
        state.loggedUser.points = points
      }
    },
    SET_UNREAD_NOTIFICATIONS(state, count) {
      state.unreadNotifications = count
    },
    SET_UNREAD_ISSUES(state, count) {
      state.unreadIssues = count;
    },
    UPDATE_AGREEMENTS(state, agreements) {
      if (state.loggedUser) {
        state.loggedUser.agreements = agreements;
      }
    }
  },
  actions: {
    loadAppConfig({ commit }) {
      api.getAppConfig()
          .then(response => {
            commit('SET_CONFIG', response.data);

            if (response.data?.backgroundColor) {
              document.getElementById('app').style.backgroundColor = response.data.backgroundColor;
            }
          })
    },
    loadPromotionalEvents({ state }) {
      if (state.events.length === 0) {
        api.getPromotionalEvents()
            .then(response => {
              state.events = response.data
            })
      }
    },
    loadUnreadNotifications({ commit }) {
      api.getUnreadNotifications()
          .then(response => {
            commit('SET_UNREAD_NOTIFICATIONS', response.data)
          });
    },
    loadUnreadIssuesCount({ commit }) {
      api.getUnreadIssuesCount()
          .then(response => {
            commit('SET_UNREAD_ISSUES', response.data)
          });
    },
    prepareInstallationListeners({ state }) {
      window.addEventListener("beforeinstallprompt", e => {
        e.preventDefault();
        state.deferredPrompt = e;
      });
      window.addEventListener("appinstalled", () => {
        state.deferredPrompt = null;
      });
    },
    installApplication({ state }) {
      if (!state.deferredPrompt) {
        return;
      }

      state.deferredPrompt.prompt();
    }
  },
  modules: {
  }
})
