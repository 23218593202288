<template>
  <main>
    <div v-loading="loading" class="row">
      <div v-for="(acc, index) in acceptedAgreements" :key="'acc'+index" class="col-12 mb-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" disabled :checked="true">
            <div class="label_container">
              <label class="form-check-label" :for="'check_'+acc.uuid">
                <div v-html="acc.short_content"></div>
              </label>
            </div>
          </div>
        </div>
      <div class="col-12">
        <agreement-set v-if="availableAgreements && availableAgreements.length > 0" v-model:agreements="updatedAgreements"
                       :errors="errors"
                       :available-agreements="availableAgreements"></agreement-set>
      </div>

      <div v-if="availableAgreements && availableAgreements.length > 0" class="col-12">
        <button class="btn btn-dark" @click="handleSubmit">{{ $dictionary.agreements_view_submit_button_label }}</button>
      </div>

      <div class="col-12 mt-5">
        <p>{{ $dictionary.agreements_view_revoke_info }}</p>
      </div>
    </div>
  </main>
</template>

<script>
import AgreementSet from "../../../components/AgreementSet";
import api from '../../../api'
import {ElNotification} from "element-plus";

export default {
  name: "Agreements",
  components: {AgreementSet},
  data() {
    return {
      updatedAgreements: null,
      errors: {},
      loading: false
    }
  },
  computed: {
    availableAgreements() {
      return this.$store.state.loggedUser.agreements ? this.$store.state.loggedUser.agreements.available_to_accept : null
    },
    acceptedAgreements() {
      return this.$store.state.loggedUser.agreements ? this.$store.state.loggedUser.agreements.accepted : null
    }
  },
  methods: {
    handleSubmit() {
      this.loading = true
      api.updateAgreements({ agreements: this.updatedAgreements })
        .then((response) => {
          ElNotification.success({
            title: this.$dictionary.form_agreements_update_success_message,
          })

          const agreements = response.data.agreements;
          this.$store.commit('UPDATE_AGREEMENTS', agreements);
        })
        .catch((e) => {
          if(e.response.status === 422) this.errors = e.response.data.errors
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style scoped>

</style>
