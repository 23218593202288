<template>
  <div class="listing-item-brand-container" :class="position">
    <img :src="image"/>
  </div>
</template>

<script>
export default {
  name: "ListingItemBrand",
  props: {
    image: String,
    position: {
      type: String,
      default: 'top',
    }
  }
}
</script>

<style scoped lang="scss">
.listing-item-brand-container {
  @extend %listing-item-brand-container;
}
</style>