<template>
  <div class="account-info-aside-container">
    <img :src="$theme.media.logoDark"/>
    <div v-if="pointsVisible" class="account-info-aside-points-container">
      <span>Twoje punkty w programie</span>
      <span>{{ $store.state.loggedUser && $store.state.loggedUser.loyalty_points && $store.state.loggedUser.loyalty_points.available ? $store.state.loggedUser.loyalty_points.available : 0 }}<small>pkt</small></span>
    </div>
  </div>
</template>
<script>
export default {
  name: "AccountInfoAside",
  computed: {
    pointsVisible() {
      let promotionalEvents = this.$store?.state?.config?.promotionalEvents;

      if (promotionalEvents && promotionalEvents.length > 0) {
        for (let promotionalEvent of promotionalEvents) {
          if (promotionalEvent.eventType === 'loyalty_points') {
            return true;
          }
        }
      }

      return false;
    }
  }
}
</script>

<style scoped lang="scss">
.account-info-aside-container {
  @extend %account-info-aside-container;
}

.account-info-aside-points-container {
  @extend %account-info-aside-points-container;
}
</style>