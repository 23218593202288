<template>
  <main class="view-container-event">
    <div class="container">
      <div class="row">
        <div class="col-12 d-block d-lg-none mt-4 mb-2">
          <router-link to="/events" class="back-icon text-white text-decoration-none"><i class="icon-angle-left"></i> {{ $dictionary.page_return_button_label}}</router-link>
        </div>
        <div class="col-12 col-lg-5 col-xl-4">
          <div class="event-data-container">
            <img :src="event.thumbnail_url"/>
            <span>{{ $dictionary.event_view_entity_title }}</span>
            <h2>{{ event.name }}</h2>
<!--            <span class="time">20 - 22 listopada 2021</span>-->
          </div>
        </div>

        <div class="col-12 col-lg-7 offset-xl-1 col-xl-7 event-tabbable-content">
          <ul class="nav nav-tabs" role="tablist">
            <li v-if="event.description" class="nav-item" role="presentation">
              <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#general" type="button" role="tab" aria-controls="general" aria-selected="true">{{ $dictionary.event_view_tab_label_info }}</button>
            </li>
            <li v-if="offers && event.is_offers" class="nav-item" role="presentation">
              <button class="nav-link" data-bs-toggle="tab" data-bs-target="#offers" type="button" role="tab" aria-controls="offers" aria-selected="false">{{  $dictionary.event_view_tab_label_offers }}</button>
            </li>
            <li v-if="rewards" class="nav-item" role="presentation">
              <button class="nav-link" data-bs-toggle="tab" data-bs-target="#rewards" type="button" role="tab" aria-controls="rewards" aria-selected="false">{{ $dictionary.event_view_tab_label_rewards }}</button>
            </li>
            <li v-if="partners && event.is_partners" class="nav-item" role="presentation">
              <button class="nav-link" data-bs-toggle="tab" data-bs-target="#partners" type="button" role="tab" aria-controls="partners" aria-selected="false">{{ $dictionary.event_view_tab_label_partners}}</button>
            </li>
            <li class="nav-item" role="presentation" v-for="(p, index) in pages" :key="'page_'+index">
              <button class="nav-link" data-bs-toggle="tab" :data-bs-target="'#page_'+index" type="button" role="tab" aria-controls="rules" aria-selected="false">{{ p.title }}</button>
            </li>
          </ul>
          <div class="tab-content">
            <div v-if="event.description" class="tab-pane show active" id="general" role="tabpanel" aria-labelledby="general-info-tab" v-html="event.description"></div>
            <div v-if="offers" class="tab-pane" id="offers" role="tabpanel" aria-labelledby="offers-tab">
              <div class="row row-cols-1 row-cols-lg-2">
                <offer-listing :offers="offers"/>
              </div>
            </div>
            <div v-if="rewards" class="tab-pane" id="rewards" role="tabpanel" aria-labelledby="rewards-tab">
              <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                <div class="col mb-4" v-for="(item, index) in rewards" :key="index">
                  <reward-listing-item @click="$router.push('/rewards/x')" image="https://via.placeholder.com/800"></reward-listing-item>
                </div>
              </div>
            </div>
            <div v-if="partners" class="tab-pane" id="partners" role="tabpanel" aria-labelledby="partners-tab">
              <div class="row row-cols-3">
                <div class="col mb-4" v-for="(item, index) in partners" :key="index">
                  <img :src="item.thumbnail_url" class="img-fluid">
                </div>
              </div>
            </div>
            <div class="tab-pane" v-for="(p, index) in pages" :key="'content_'+index" :id="'page_'+index" role="tabpanel" :aria-labelledby="'page-'+index+'-tab'">
              <div v-html="p.body"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import OfferListing from "@/components/OfferListing";
import RewardListingItem from "@/components/listing-items/RewardListingItem";

export default {
  name: "Event",
  components: {RewardListingItem, OfferListing},
  data(){
    return {
      rewards: false,
      generalInfo: null,
    }
  },
  computed: {
    event() {
      return this.$store.state.events.find(e => e.uuid === this.$route.params.uuid)
    },
    partners() {
      return this.event.partners
    },
    offers() {
      return this.event.offers
    },
    pages() {
      return this.event.pages
    },
  },
  created() {
    this.$store.dispatch('loadPromotionalEvents');
  },
}
</script>

<style scoped lang="scss">
.back-icon {
  text-decoration: none;
  color: $black;
  font-size: 1.5rem;
}

.view-container-event {
  @extend %view-container-event;
}

.event-data-container {
  img {
    max-width: 100%;
  }

  @extend %event-data-container !optional;
}

.event-tabbable-content {
  @extend %event-tabbable-content !optional;
}
</style>
