<template>
  <div>
    <heading :heading="title">
      <div class="d-none d-lg-flex flex-row gap-lg-2">
        <div @click="scrollNegative" class="flex-shrink-0 carousel-control-btn">
          <i class="icon-angle-left"></i>
        </div>
        <div @click="scrollPositive" class="flex-shrink-0 carousel-control-btn">
          <i class="icon-angle-right"></i>
        </div>
      </div>
    </heading>
    <carousel-container
        ref="container"
        :scroll="scrollValue"
        v-touch:swipe.left="scrollPositive"
        v-touch:swipe.right="scrollNegative"
    >
      <slot></slot>
    </carousel-container>
    <div
        class="vueperslides__bullets"
        role="tablist"
        aria-label="Slideshow navigation"
    >
      <div v-for="bullet in bullets" :key="bullet">
        <button
            :class="
            bullet == active
              ? 'vueperslides__bullet vueperslides__bullet--active'
              : 'vueperslides__bullet'
          "
            type="button"
            role="tab"
            @click="scrollBullet(bullet)"
        >
          <div class="default"><span>1</span></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "./Heading";
import CarouselContainer from "./CarouselContainer";
export default {
  name: "Carousel",
  components: { CarouselContainer, Heading },
  props: {
    title: String,
    sliderLength: Number,
  },
  data() {
    return {
      scrollValue: 0,
      active: 0,
    };
  },
  computed: {
    scrollLength() {
      return this.$refs.container.offsetWidth
          ? this.$refs.container.offsetWidth
          : this.getChildWidth();
    },
    divider() {
      if (window.innerWidth <= 767) {
        return 1;
      } else if (window.innerWidth <= 991) {
        return 2;
      } else {
        return 3;
      }
    },
    bullets() {
      let bullets = [];
      for (
          let index = 0;
          index < Math.ceil(this.sliderLength / this.divider);
          index++
      ) {
        bullets[index] = index;
      }
      return bullets;
    },
  },
  methods: {
    getChildWidth() {
      let width = this.$refs.container.$el.children[0].offsetWidth;
      return width + 24; // add gap value
    },
    scrollPositive() {
      this.scrollValue += this.scrollLength;
      this.active++;
    },
    scrollNegative() {
      this.scrollValue =
          this.scrollValue >= this.scrollLength
              ? this.scrollValue - this.scrollLength
              : 0;
      this.active--;
    },
    scrollBullet(bullet) {
      this.scrollValue = 0;
      if (bullet == 0) {
        this.scrollValue = 0;
      } else {
        this.scrollValue += bullet * this.scrollLength;
      }
      this.active = this.bullets[bullet];
    },
  },
};
</script>

<style scoped lang="scss">
.carousel-control-btn {
  @extend %carousel-control-btn;
}

.vueperslides__bullets {
  @extend %carousel-vueperslides-bullets;
}
</style>
