<template>
  <div class="d-flex flex-row gap-3 issue-list-item">
    <div class="d-flex flex-column">
      <div class="d-flex flex-row gap-3">
        <div>
          <h5>{{ title }}</h5>
          <p>{{ body }}</p>
        </div>
        <div>
          <div v-if="isUnread" class="issue-indicator"></div>
        </div>
      </div>
      <div class="text-end">
        <span>{{ time }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IssuesListingItem",
  props: {
    title: String,
    body: String,
    time: String,
    isUnread: Number
  }
}
</script>

<style scoped lang="scss">
.issue-list-item {
  @extend %issue-list-item !optional;
}
</style>
