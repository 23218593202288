const MOBILE_BREAKPOINT = 992;
const THEME = process.env.VUE_APP_THEME

export default {
    computed: {
        isMobile() {
            return Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            ) < MOBILE_BREAKPOINT;
        },
        theme() {
            return THEME
        }
    }
}