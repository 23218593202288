<template>
  <nav class="nav-mobile-container">
    <router-link to="/start" class="nav-start">
      <i class="icon-dashboard"></i>
      <span>{{ $dictionary.nav_mobile_label_start }}</span>
      <template v-if="$store.state.config.features.notifications">
        <span class="indicator-unread" v-if="$store.state.unreadNotifications">{{ $store.state.unreadNotifications }}</span>
      </template>
    </router-link>

    <router-link v-if="$store.state.config.features && $store.state.config.features.events" :class="{'router-link-active' : $route.matched.some(({name}) => name === 'Event')}" to="/events"><i class="icon-event"></i><span>{{ $dictionary.nav_mobile_label_events  }}</span></router-link>
    <router-link v-if="$store.state.config.features && $store.state.config.features.card" to="/card"><i class="icon-card"></i><span>{{ $dictionary.nav_mobile_label_card }}</span></router-link>
    <router-link v-if="$store.state.config.features && $store.state.config.features.receiptScan" to="/receipts/scan"><i class="icon-receipt"></i><span>{{ $dictionary.nav_mobile_label_scan  }}</span></router-link>
    <router-link to="/account"><i class="icon-user"></i><span>{{ $dictionary.nav_mobile_label_account  }}</span></router-link>
  </nav>
</template>

<script>
export default {
  name: "MobileNavBar"
}
</script>

<style scoped lang="scss">
.nav-mobile-container {
  @extend %nav-mobile-container;
}
</style>
