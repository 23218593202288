<template>
  <main class="view-container-home">
    <div class="container">
      <div class="row mb-5">
        <div class="col-5 d-none d-md-block">
          <auth-forms-container></auth-forms-container>
        </div>

        <div class="col">
          <div class="row mb-5">
            <div class="col">
              <img class="w-100" :src="homeHeader"/>
            </div>
          </div>
          <div class="row d-block mb-5 mb-lg-0">
            <div class="col d-md-none text-center">
              <div class="d-grid gap-3">
                <router-link to="/register" class="btn btn-dark">{{ getTranslationsMix($dictionary.form_authorization_register_button_label) }}</router-link>
                <router-link to="/login" class="btn btn-outline-dark">{{ getTranslationsMix($dictionary.form_authorization_login_button_label) }}</router-link>
                <i class="icon-angle-down mt-4"></i>
              </div>
            </div>
          <div class="row mt-5 mt-lg-0">
            <div class="col">
              <div class="intro-text-container">
                <h3 v-html="getTranslationsMix($dictionary.homeViewHeader)"></h3>
                <p v-html="$t($dictionary.homeViewDescription, 'pl')"></p>
                <p v-if="translationEnabled == 1" v-html="$t($dictionary.homeViewDescription, 'en')"></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="$store.getters.featuredOffers && $store.getters.featuredOffers.length > 0" class="row offers_slider_container">
        <div v-if="$store.state.config.features.position === true">
          <carousel :sliderLength="offersLength" :title="getTranslationsMix('Oferty specjalne')">
            <div class="col">
              <div
                  style="flex-wrap: nowrap"
                  class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 special-offers-list-container"
              >
                <offer-listing
                    :dashboard-mode="false"
                    :offers="offers"
                    :cols="true"
                    :sliderLength="offers.length"
                />
              </div>
            </div>
          </carousel>
        </div>
        <div v-else>
          <div id="slider-vertical" class="row">
            <h2>{{ getTranslationsMix('Oferty specjalne')}}</h2>
            <div class="col">
              <div
                  class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 special-offers-list-container"
              >
                <offer-listing
                    :dashboard-mode="false"
                    :offers="offers"
                    :cols="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="home-app-download-info">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 d-md-block d-none">
            <img class="w-100 d-none d-lg-block" :src="$theme.media.homeAppDownload"/>
            <img class="w-100 d-lg-none" :src="$theme.media.homeAppDownloadMobile"/>
          </div>
          <div v-if="!isAppleOs()" class="col-12 col-lg-6 d-flex flex-column justify-content-center download-info">
            <img class="w-100 title-image d-block" :src="$theme.media.homeAppDownloadTitle"/>
<!--            <h2 class="mt-2">{{ $t($dictionary.home_download_app, 'pl')}}</h2>-->
            <h2 v-if="translationEnabled == 1" class="mt-2">{{ $t($dictionary.home_download_app, 'en')}}</h2>
            <div class="mt-2" v-html="$dictionary.app_download_ios_description"></div>
            <div v-if="translationEnabled == 1" class="mt-4" v-html="$dictionary.app_download_ios_description_en"></div>
          </div>
          <div v-else class="col-12 col-lg-6 d-flex flex-column justify-content-center download-info">
            <img class="w-100 title-image d-block" :src="$theme.media.homeAppDownloadTitle"/>
<!--            <h2 class="mt-2">{{ $t($dictionary.home_download_app, 'pl')}}</h2>-->
            <h2 v-if="translationEnabled == 1" class="mt-2">{{ $t($dictionary.home_download_app, 'en')}}</h2>
            <div class="mt-4" v-html="$t($dictionary.homeAppDownloadDescription, 'pl')"></div>
            <div v-if="translationEnabled == 1" class="mt-4" v-html="$t($dictionary.homeAppDownloadDescription, 'en')"></div>
            <div class="store-badges d-flex justify-content-center gap-4">
              <button class="btn btn-light" @click="install">{{ getTranslationsMix($dictionary.appInstallBtnLabel) }}</button>
              <a v-if="false" :href="$dictionary.appDownloadLinkIos" target="_blank"><img class="img-fluid" :src="$theme.media.appStoreBadge"/></a>
              <a v-if="false" :href="$dictionary.appDownloadLinkAndroid" target="_blank"><img class="img-fluid" :src="$theme.media.googlePlayBadge"/></a>
            </div>
          </div>
          <div class="col-12 col-lg-6 d-md-none block">
            <img class="w-100 d-none d-lg-block" :src="$theme.media.homeAppDownload"/>
            <img class="w-100 d-lg-none" :src="$theme.media.homeAppDownloadMobile"/>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AuthFormsContainer from "../../components/AuthFormsContainer";
import {hasOsInfo} from "@/mixins/utilities";
import OfferListing from "@/components/OfferListing";
import Carousel from "@/components/Carousel";

export default {
  name: 'Home',
  components: {Carousel, OfferListing, AuthFormsContainer},
  mixins: [hasOsInfo],
  data() {
    return {
      deferredPrompt: null,
      offersLength: null,
      translationEnabled: process.env.VUE_APP_ENABLE_TRANSLATIONS_MIX
    }
  },
  computed: {
    homeHeader() {
      return this.$store.getters.mediaHomeHeader ? this.$store.getters.mediaHomeHeader : this.$theme.media.homeHeader;
    },
    offers() {
      let offersList = [];

      if (this.$store.getters?.featuredOffers) {
        for (const offer of this.$store.getters.featuredOffers) {
          offersList.push(offer);
        }
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.offersLength = offersList.length
      return offersList;
    },
    rewardsList() {
      let rewardsList = [];
      if (this.$store.getters?.featuredRewards) {
        for (const reward of this.$store.getters.featuredRewards) {
          rewardsList.push(reward);
        }
      }
      return rewardsList;
    },
  },
  created() {
    this.$store.dispatch('prepareInstallationListeners');
  },
  methods: {
    async install() {
      this.$store.dispatch('installApplication');
    },
  }
}
</script>

<style lang="scss" scoped>
.intro-text-container {
  @extend %intro-text-container;
}
.view-container-home {
  @extend %view-container-home;
}
.offers_slider_container {
  @extend %offers-slider-container;
}
.home-app-download-info {
  @extend %home-app-download-info;
}
#slider-vertical .few-elements {
  flex: unset;
  margin: unset;
}
</style>
