<template>
  <div class="view-container-start">
    <main>
      <div class="container">
        <div class="row mt-3"></div>
        <div class="row mobile-only">
          <div class="col-2 offset-10 text-right">
            <router-link v-if="$store.state.config.features.notifications" to="/notifications"
                         class="notification-mobile-icon mx-2">
              <i class="icon-bell"></i>
              <span class="indicator-unread" v-if="$store.state.unreadNotifications">{{
                  $store.state.unreadNotifications
                }}</span>
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <view-title
                :disable-back="true"
                :header-class="'section-title'"
                :title="welcomeTitle"
            ></view-title>
          </div>
        </div>

        <div class="row my-4">
          <div v-if="$store.state.config.features.events" class="col mobile-only">
            <customer-points-mobile></customer-points-mobile>
          </div>
        </div>
        <!-- todo: Move slides into new Component -->
        <app-slider :slides="slides"></app-slider>

        <div
            v-if="
            $store.getters.featuredRewards &&
            $store.getters.featuredRewards.length > 0 &&
            $store.state.config.features.rewardsCatalogue"
            class="row offers_slider_container">
          <div v-if="$store.state.config.features.position === true">
            <carousel :sliderLength="rewardLength" title="Nagrody">
              <div class="col">
                <div
                    style="flex-wrap: nowrap"
                    class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 special-offers-list-container"
                >
                  <offer-listing
                      :dashboard-mode="false"
                      :offers="rewardsList()"
                      :cols="true"
                  />
                </div>
              </div>
            </carousel>
          </div>
          <div v-else>
            <div id="slider-vertical" class="row">
              <h2>Nagrody</h2>
              <div class="col">
                <div
                    class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 special-offers-list-container"
                >
                  <offer-listing
                      :dashboard-mode="false"
                      :offers="rewardsList()"
                      :cols="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="$store.state.config.features.rewardsCatalogue" class="row rewards_slider_container">
          <div class="col">
            <div class="text-center">
              <router-link
                  to="/rewards"
                  class="btn btn-outline-dark mt-5 align-self-center"
              >Pokaż wszystkie
              </router-link
              >
            </div>
          </div>
        </div>

        <div class="row my-5">
          <div
              v-if="$store.state.config.features.rewardsForPoints"
              class="d-flex flex-row decorated-heading-container container-barcode"
          >
            <h2>{{ $dictionary.marketing_text_eko }}</h2>
          </div>
          <div v-if="$store.state.config.features.rewardsForPoints" class="col">
            <account-info-mobile></account-info-mobile>
          </div>
        </div>

        <!-- todo: Move rewardsForPoints into new Component -->
        <div class="row my-5" v-if="$store.state.config.features.rewardsForPoints">
          <div class="d-flex flex-row decorated-heading-container container-barcode">
            <h2>{{ $dictionary.add_receipt_from_machine }}</h2>
          </div>
          <div class="mb-3">
            <span><strong>{{ $dictionary.customer_points_label }}: </strong><br/></span>
            <span>{{ $dictionary.customer_points_total }}: </span><span>{{
              $store.state.loggedUser.points.total
            }} <small> {{ $dictionary.points }}.</small><br/></span>
            <span>{{ $dictionary.customer_points_issued }}: </span><span>{{
              $store.state.loggedUser.points.issued
            }} <small> {{ $dictionary.points }}.</small><br/></span>
            <span>{{ $dictionary.customer_points_available }}: </span><span>{{
              $store.state.loggedUser.points.available
            }} <small> {{ $dictionary.points }}.</small><br/></span>
          </div>

          <div class="col mt-3" v-if="$store.state.config.features.rewardsForPoints">
            <div class="input-group">
              <input type="text" v-model="barcode" @change="setFocus" class="form-control col-12 col-md-6 input-barcode"
                     :placeholder="$dictionary.provide_barcode" aria-describedby="basic-addon2">
              <div class="input-group-append col-12 col-md-auto">
                <div class="d-flex flex-column flex-md-row mt-3 mt-md-0 ms-md-3">
                  <button class="btn btn-outline-secondary" @click="showModal" ref="scanCode" type="button">
                    {{ $dictionary.scan_code_button }}
                  </button>
                  <button class="btn btn-outline-secondary mt-3 mt-md-0 ms-md-3" ref="sendCode" @click="sendCode"
                          type="button">{{ $dictionary.send_barcode }} >
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="$store.state.config.features.rewardsForPoints" class="mt-4 white-color"><h6>
            {{ $dictionary.eko_info_text_after_form }}</h6></div>
        </div>

        <div v-loading="loading" class="row">
          <div class="col">
          </div>
        </div>

        <!-- todo: Move featuredOffers into new Component -->
        <div class="row offers_slider_container mt-5" v-if="
                $store.getters.featuredOffers &&
                $store.getters.featuredOffers.length > 0
              ">
          <div class="col">
            <div class="row offers_slider_container"
            >
              <div v-if="$store.state.config.features.position === true">
                <carousel :sliderLength="offersLength" title="Oferty specjalne">
                  <div class="col">
                    <div
                        style="flex-wrap: nowrap"
                        class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 special-offers-list-container"
                    >
                      <offer-listing
                          :dashboard-mode="false"
                          :offers="offers"
                          :cols="true"
                      />
                    </div>
                  </div>
                </carousel>
              </div>
              <div v-else>
                <div id="slider-vertical" class="row">
                  <h2>Oferty specjalne</h2>
                  <div class="col">
                    <div
                        class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 special-offers-list-container"
                    >
                      <offer-listing
                          :dashboard-mode="false"
                          :offers="offers"
                          :cols="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center">
              <router-link
                  to="/offers"
                  class="btn btn-outline-dark mt-5 align-self-center"
              >{{
                  $dictionary.start_view_load_more_offers_label
                }}
              </router-link
              >
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- todo: Move scannerActive into new Component -->
    <div v-if="scannerActive && $store.state.config.features.rewardsForPoints" ref="modal" class="modal"
         v-bind:class="{ active: scannerActive, 'show': 'fade' }" id="scanner_modal" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Zeskanuj kod z paragonu</h5>
            <button type="button" class="close" @click="hideModal" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <span class="text-info">{{ $dictionary.barcode_scanner_info }}</span>
          <div class="modal-body">
            <camera-scanner @decoded="decodedCode"></camera-scanner>
          </div>
        </div>
      </div>
    </div>
    <div class="home-app-download-info">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <img class="w-100 d-none d-lg-block" :src="$theme.media.homeAppDownload"/>
            <img class="w-100 d-lg-none d-block" :src="$theme.media.homeAppDownloadMobile"/>
          </div>
          <div v-if="isAppleOs()" class="col-12 col-lg-6 d-flex flex-column justify-content-center download-info">
            <img class="w-100 title-image d-block d-lg-none" :src="$theme.media.homeAppDownloadTitlePrimary"/>
            <img class="w-100 title-image d-none d-lg-block" :src="$theme.media.homeAppDownloadTitlePrimary"/>
            <h5 class="mt-4" v-html="$dictionary.app_download_ios_description"></h5>
            <h5 v-if="translationEnabled == 1" class="mt-2" v-html="$dictionary.app_download_ios_description_en"></h5>
          </div>
          <div v-else class="col-12 col-lg-6 d-flex flex-column justify-content-center download-info">
            <img class="w-100 title-image d-block d-lg-none" :src="$theme.media.homeAppDownloadTitlePrimary"/>
            <img class="w-100 title-image d-none d-lg-block" :src="$theme.media.homeAppDownloadTitlePrimary"/>
            <h5 v-html="$t($dictionary.homeAppDownloadDescription, 'pl')"></h5>
            <h5 v-if="translationEnabled == 1" v-html="$t($dictionary.homeAppDownloadDescription, 'en')"></h5>
            <div class="store-badges d-flex justify-content-center justify-content-lg-start gap-4">
              <button class="btn btn-light" @click="install">{{ getTranslationsMix($dictionary.appInstallBtnLabel) }}</button>
              <a v-if="false" :href="$dictionary.appDownloadLinkIos" target="_blank"><img class="img-fluid" :src="$theme.media.appStoreBadge"/></a>
              <a v-if="false" :href="$dictionary.appDownloadLinkAndroid" target="_blank"><img class="img-fluid" :src="$theme.media.googlePlayBadge"/></a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ViewTitle from "../../components/ViewTitle";
import Carousel from "../../components/Carousel";
import api from "../../api";
import OfferListing from "@/components/OfferListing";
import CameraScanner from "../../components/CameraScanner";
import {ElNotification} from "element-plus";
import CustomerPointsMobile from "@/components/CustomerPointsMobile";
import AppSlider from "@/components/AppSlider";
import AccountInfoMobile from "@/components/AccountInfoMobile";
import {hasOsInfo} from "@/mixins/utilities";

export default {
  name: "Start",
  components: {
    AccountInfoMobile,
    AppSlider, CustomerPointsMobile, OfferListing, Carousel, ViewTitle, CameraScanner
  },
  mixins: [hasOsInfo],
  data() {
    return {
      slides: [],
      rewards: [],
      offers: [],
      loading: false,
      scannerActive: false,
      barcode: null,
      timer: null,
      rewardLength:null,
      offersLength:null,
      deferredPrompt: null,
      translationEnabled: process.env.VUE_APP_ENABLE_TRANSLATIONS_MIX
    }
  },
  created() {
    this.loading = true
    this.$store.dispatch('loadPromotionalEvents');

    this.getSlides();
    this.getOffers();
  },
  mounted() {
    this.$store.dispatch('loadUnreadNotifications');
    this.$store.dispatch('loadUnreadIssuesCount');
    this.$store.dispatch('prepareInstallationListeners');
  },
  computed: {
    welcomeTitle() {
      return this.$dictionary.start_view_welcome_prefix
          + ((!!this.$store.state.loggedUser.personal_identity?.first_name ?? false) ? ', ' : '')
          + (this.$store.state.loggedUser.personal_identity?.first_name ?? '')
          + '!'
    }
  },
  methods: {
    getOffers() {
      let offersList = [];

      if (this.$store.getters?.featuredOffers) {
        for (const offer of this.$store.getters.featuredOffers) {
          offersList.push(offer);
        }
      }

      this.offersLength = offersList.length;
      this.offers = offersList;
    },
    getSlides() {
      api.getSlides()
          .then(response => {
            this.slides = response.data;
          }).finally(() => {
            this.loading = false;
          });
    },
    showModal() {
      let counter = 1;
      this.$refs['scanCode'].classList.remove('inactive');
      this.$refs['scanCode'].blur();

      const self = this;

      this.$refs['sendCode'].classList.remove('send');

      this.timer = setInterval(function () {
        if (counter >= 8) {
          clearInterval(self.timer);
          self.scannerOff();
        } else if (self.barcode.length > 0) {
          clearInterval(self.timer);
        }

        counter++;
      }, 1000);

      this.scannerActive = true
      this.barcode = '';
      this.$refs['scanner'].isActive = true
    },
    hideModal() {
      this.scannerActive = false
      this.setFocus();

      clearInterval(this.timer);

      this.$refs['scanner'].isActive = false;
    },
    decodedCode(e) {
      this.barcode = e;
      this.hideModal();
    },
    sendCode() {
      api.sendBarCode(this.barcode, this.$store.state.loggedUser.uuid)
          .then(response => {
            if (response.status === 201) {
              ElNotification.success({
                title: 'Gratulacje, na Twoim koncie pojawiły się kolejne punkty!',
              });

              this.$store.commit('SET_USER_POINTS', response.data)
              this.barcode = '';
            } else if (response.status === 203) {
              ElNotification.error({
                title: response.data,
              });
            }
          });
    },
    scannerOff() {
      this.scannerActive = false;

      if (this.scannerActive === false) {
        ElNotification.warning({
          title: 'Skanowanie nie powiodło się. Przepisz kod widoczny pod kodem kreskowym w dostępnym formularzu.',
        });

        this.barcode = '';
        this.$refs['scanCode'].classList.add('inactive');
      }
    },
    rewardsList() {
      let rewardsList = [];

      if (this.$store.getters?.featuredRewards) {
        for (const reward of this.$store.getters.featuredRewards) {
          rewardsList.push(reward);
        }
      }

      this.rewardLength = rewardsList.length;

      return rewardsList;
    },
    setFocus() {
      this.$refs['sendCode'].focus();
      this.$refs['scanCode'].classList.remove('inactive');

      if (this.barcode.length > 0) {
        this.$refs['sendCode'].classList.add('send');
        this.$refs['scanCode'].blur();
        this.$refs['scanCode'].classList.add('inactive');
      } else {
        this.$refs['sendCode'].classList.remove('send');
      }
    },
    async install() {
      this.$store.dispatch('installApplication');
    },
  },
}
</script>

<style>
#scanner_modal .close {
  background: transparent;
  border: 0;
}

#scanner_modal .close span {
  color: #fff;
}

#scanner_modal .modal-content {
  background: #191A1E;
  border: 1px solid #6e6e6e;
}
</style>

<style scoped lang="scss">
.view-container-start {
  @extend %view-container-start;
}
</style>
