<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col">
          <view-title back-location="/" :title="getTranslationsMix('Logowanie')"></view-title>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <login-form></login-form>
        </div>
      </div>
      <div class="row my-5">
        <div class="col text-center">
          <router-link to="/register" class="btn btn-outline-dark">{{ getTranslationsMix($dictionary.form_authorization_register_button_label) }}</router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import LoginForm from "../../components/forms/LoginForm";
import ViewTitle from "../../components/ViewTitle";
export default {
  name: "Login",
  components: {ViewTitle, LoginForm}
}
</script>

<style scoped>

</style>
