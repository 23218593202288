<template>
  <div class="view-title-container">
    <div class="view-title-container-inside">
      <div v-if="$attrs.onBack" @click="$emit('back')" class="back-icon-container"><i class="icon-angle-left"></i></div>
      <div
          v-else-if="!disableBack"
          @click="$router.push(backLocation ? backLocation : '/start')"
          class="d-lg-none back-icon-container">
        <i class="icon-angle-left"></i>
      </div>

      <h1 :class="headerClass">{{ title }}</h1>
    </div>
    <div v-if="$slots.default">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewTitle",
  props: {
    headerClass: String,
    title: String,
    disableBack: Boolean,
    backLocation: {
      type: String,
      default: null
    },
    fontSize: String,
    lineHeight: String
  },
}
</script>

<style scoped lang="scss">
.view-title-container {
  @extend %view-title-container;
}
</style>
