<template>
  <main>
    <div v-loading="loading" class="container">
      <div class="row">
        <div class="col">
          <view-title
              :header-class="'page-title'"
              :back-location="$store.state.loggedIn ? '/start' : '/'" :title="page ? page.title : ''"
          ></view-title>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div v-if="page" v-html="page.body"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mt-3">
          <div class="d-grid gap-3">
            <router-link :to="{ name: 'Home' }" class="btn btn-outline-dark">{{ $dictionary.page_return_button_label }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import api from "../../api";
import ViewTitle from "../../components/ViewTitle";

export default {
  name: "Page",
  components: {ViewTitle},
  data() {
    return {
      page: null,
      loading: false,
    }
  },
  computed: {
    uuid() {
      return this.$route.params.uuid
    }
  },
  created() {
    this.loadPage()
  },
  methods: {
    loadPage() {
      this.loading = true
      api.getPage(this.uuid)
          .then(r => { this.page = r.data; })
          .finally(() => { this.loading = false })
    }
  }
}
</script>

<style scoped>

</style>
