<template>
  <div>
    <div class="reward-item-container position-relative">
      <div class="overlay unlocked" :class="{'increased without-gradient': !withDetails }">
        <span v-html="overlayMessage"></span>
      </div>
      <div class="reward-item__box">
        <div class="reward-item__box__image">
          <img :src="reward ? reward.thumbnail_url : $theme.media.placeholderReward"/>
        </div>
        <listing-item-brand
            v-if="brandImage"
            :image="brandImage ? brandImage : null"
        ></listing-item-brand>
        <div v-if="withDetails" class="reward-item-info-container points-needed">
          <span class="prefix">Należy zebrać</span>
          <span>{{ reward ? reward.points_needed : 0 }} <small>pkt</small></span>
        </div>
        <div v-if="withDetails" class="reward-item-info-container lock">
          <i class="fa" :class="{ 'icon-lock': reservationDisabled, 'icon-lock-open': !reservationDisabled }"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListingItemBrand from "./ListingItemBrand";
export default {
  name: "RewardListingItem",
  components: { ListingItemBrand},
  props: {
    reward: Object,
    collectedPoints: Number,
    brandImage: String,
    withDetails: Boolean
  },
  computed: {
    overlayMessage() {
      let message = '';

      if ((this.reward && this.reward.reservation ? this.reward.reservation : null) === null && this.collectedPoints >= (this.reward && this.reward.points_needed ? this.reward.points_needed : 0)) {
        message = '<b>Gratulacje</b> - masz wystarczającą liczbę punktów';
      } else if (this.reward && this.reward.reservation && this.reward.reservation.status === 'collected') {
        message = '<b>ODEBRANO NAGRODĘ</b>';
      } else if (this.reward && this.reward.reservation && this.reward.reservation.status === 'reserved') {
        message = '<b>NAGRODA ZAREZERWOWANA</b>';
      } else if (this.reward && this.reward.reservation && this.reward.reservation.status === 'expired') {
        message = '<b>NIE ODEBRANO W TERMINIE</b>';
      }

      return message;
    },
    reservationDisabled() {
      return !(
          (this.reward && this.reward.reservation ? this.reward.reservation : null) === null
          &&
          (this.reward && this.reward.points_needed ? this.reward.points_needed : 0) <= this.collectedPoints
      );
    },
  }
}
</script>

<style scoped lang="scss">
  .reward-item-container {
    @extend %reward-item-container;
  }

  .reward-item-info-container {
    @extend %reward-item-info-container;
  }
</style>
