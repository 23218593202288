<template>
  <div>
    <div v-loading="loading" class="row">
      <div class="col-12 mb-4">
        <p>{{ $dictionary.form_password_change_logout_info_alert }}</p>
      </div>

      <div class="col-12 mb-3">
        <el-input v-model="old_password"
                  type="password"
                  show-password
                  :placeholder="form_password_change_old_password_placeholder">
        </el-input>
        <form-control-errors :error-bag="errors" field="password_current"></form-control-errors>
      </div>

      <div class="col-12 mb-3">
        <el-input v-model="password"
                  type="password"
                  show-password
                  :placeholder="$dictionary.form_password_change_password_placeholder">
        </el-input>
        <form-control-errors :error-bag="errors" field="password"></form-control-errors>
      </div>

      <div class="col-12 mb-3">
        <el-input v-model="password_repeat"
                  type="password"
                  show-password
                  :placeholder="$dictionary.form_password_change_password_repeat_placeholder">
        </el-input>
        <form-control-errors :error-bag="errors" field="password_repeat"></form-control-errors>
      </div>

      <div class="col-12 mt-3">
        <button class="btn btn-dark" @click="handleSubmit">{{ $dictionary.form_password_change_submit_button_label }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import FormControlErrors from "../FormControlErrors";
import { ElNotification } from 'element-plus'
import api from '../../api'

export default {
  name: "PasswordChangeForm",
  components: {FormControlErrors},
  data() {
    return {
      old_password: null,
      password: null,
      password_repeat: null,
      errors: {},
      loading: false,
    }
  },
  methods: {
    handleSubmit() {
      this.loading = true
      api.updatePassword(this.old_password, this.password, this.password_repeat)
        .then(() => {
          this.old_password = null
          this.password = null
          this.password_repeat = null
          this.errors = {}
          ElNotification.success({
            title: this.$dictionary.form_password_change_success_message,
          })
        })
        .catch(e => {
          if(e.response.status === 422) this.errors = e.response.data.errors
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style scoped>

</style>