<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col">
          <view-title
              :header-class="'section-title'"
              title="Moje nagrody"
          ></view-title>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col">
          <div class="btn-group" role="group">
            <button @click="filter = 'all'" type="button" class="btn"
                    :class="{'btn-primary':filter === 'all', 'btn-outline-primary':filter !== 'all'}">Wszystkie</button>
            <button @click="filter = 'unused'" type="button" class="btn"
                    :class="{'btn-primary':filter === 'unused', 'btn-outline-primary':filter !== 'unused'}">Niewykorzystane</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
          <div class="col mb-4" v-for="(item, index) in rewardsList" :key="index">
            <reward-listing-item
                v-if="rewardVisible(item)"
                :reward="item"
                :with-details="true"
                :collectedPoints="collectedPoints"
                @click="$router.push('/rewards/' + item.uuid)"
            ></reward-listing-item>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ViewTitle from "../../../components/ViewTitle";
import RewardListingItem from "../../../components/listing-items/RewardListingItem";
import api from "@/api";
export default {
  name: "RewardsCollected",
  components: {RewardListingItem, ViewTitle},
  data() {
    return {
      rewards: [],
      rewardsList: [],
      filter: 'all',
    }
  },
  created() {
    this.getRewardsList();
  },
  watch: {
    filter() {
      this.generateRewardsList();
    }
  },
  methods: {
    getRewardsList() {
      this.rewards = [];

      api.getCustomerRewards().then(
          (response) => {
            for (let reservation of response.data) {
              let reward = reservation.reward;

              reward.status = reservation.status;
              reward.reservation = reservation;

              this.rewards.push(reward);
            }

            this.generateRewardsList();
          }
      );
    },
    generateRewardsList() {
      this.rewardsList = [];

      if (this.rewards) {
        for (let reward of this.rewards) {
          if (this.rewardVisible(reward)) {
            this.rewardsList.push(reward);
          }
        }
      }
    },
    rewardVisible(reward) {
      if (!reward) {
        return false;
      }

      if (this.filter === 'all') {
        let rewardReservation = reward && reward.reservation
        return !!(
          reward.status === 'collected' || (rewardReservation && rewardReservation.status === 'reserved')
        )
      } else {
        return (
          ['expired', 'canceled'].indexOf(reward.status) !== -1
        );
      }
    }
  },
  computed: {
    collectedPoints() {
      return parseInt(this.$store.state.loggedUser && this.$store.state.loggedUser.loyalty_points ? this.$store.state.loggedUser.loyalty_points.available : 0);
    }
  }
}
</script>

<style scoped>

</style>