<template>
  <div class="reward-container pt-5">
    <div class="reward-container__header">
      <div
          class="reward-container__header__background_image"
          :style="{ 'background-image': `url(${(reward.thumbnail_url ? reward.thumbnail_url : $theme.media.placeholderReward)})`}"
      ></div>
      <div class="reward-container__header__background_gradient"></div>
      <div
          class="reward-container__header__background_blur"
          :style="{ 'background-image': `url(${(reward.thumbnail_url ? reward.thumbnail_url : $theme.media.placeholderReward)})`}"
      ></div>
    </div>

    <div class="single-reward">
      <div class="container container-lg">
        <div class="row container__row">
          <div class="col-12 col-md-6 reward-image">
            <reward-listing-item
                v-if="reward"
                :reward="reward"
                :with-details="false"
                :collectedPoints="collectedPoints"
            ></reward-listing-item>

            <div class="row">
              <div class="col-12 mt-5">
                <h6 class="reward-label">Nagrody</h6>
              </div>
              <div class="col-12 mt-2">
                <h1 class="reward-name"><b>{{ reward.name ? reward.name : null}}</b></h1>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <div class="row">
                  <div class="col-6">
                    <h6 class="reward-label text-without-transform">Należy zebrać</h6>
                    <h3 class="reward-name text-black text-without-transform">
                      <b>{{ reward.points_needed }}</b>
                      <small> pkt</small>
                    </h3>
                  </div>
                  <div class="col-6 text-right">
                    <h6 class="reward-label text-without-transform">Pozostało</h6>
                    <h1 class="reward-name text-black text-without-transform">
                      <b>{{ (reward.warehouse_stock && reward.warehouse_stock.quantity ? reward.warehouse_stock.quantity : 0) }}</b>
                      <small> szt</small>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 reward-informations">
            <div class="progress-container white-container" v-if="!enoughPoints && (!hasReservation || !rewardReserved)">
              <div class="progress-container__block">
                <div class="progress-container__bar" :style="{ 'width': progressWidth }"></div>
              </div>
              <div class="col-12 text-center mt-5 text-black">
                <h6>BRAKUJE CI</h6>
                <h1 class="mt-1">
                  <b>{{ reward.points_needed - collectedPoints }}</b> <small>pkt</small>
                </h1>
                <h6>BY MÓC ZAREZERWOWAĆ NAGRODĘ</h6>
              </div>
            </div>

            <div class="progress-container" v-if="enoughPoints && (!hasReservation || !rewardReserved)">
              <div class="col-12 text-center">
                <h6>POSIADASZ WYSTARCZAJĄCĄ ILOŚĆ PUNKTÓW!</h6>
                <button
                    class="btn btn-white-pink-text mt-4"
                    @click="rewardReservation"
                >
                  REZERWUJ NAGRODĘ
                </button>
              </div>
            </div>

            <div class="progress-container reserved" v-if="hasReservation && rewardReserved">
              <div class="col-12 text-center">
                <h6>MOŻESZ ODEBRAĆ DO</h6>
                <h3 class="mt-1">
                  <b>{{ reservationDate }} 19:55</b>
                </h3>
                <h6>
                  Pozostało:
                  <span v-if="reward.issueTimeLeft.d > 0">
                    {{ reward.issueTimeLeft.d }}
                    <span v-if="reward.issueTimeLeft.d === 1">dzień</span>
                    <span v-if="reward.issueTimeLeft.d > 1">dni</span>
                  </span>
                  <span v-if="reward.issueTimeLeft.h > 0">
                    {{ reward.issueTimeLeft.h }} {{ hourText(reward && reward.issueTimeLeft ? reward.issueTimeLeft.h : 0) }}
                  </span>
                  <span v-if="reward.issueTimeLeft.i > 0 && (reward.issueTimeLeft.d === 0 || reward.issueTimeLeft.h === 0)">
                    {{ reward.issueTimeLeft.i }} {{ minuteText(reward && reward.issueTimeLeft ? reward.issueTimeLeft.i : 0) }}
                  </span>
                </h6>
              </div>
              <div class="col-12 text-center">
                <el-popconfirm
                    confirm-button-text="TAK"
                    cancel-button-text="NIE"
                    @confirm="cancelReservation"
                    title="Czy na pewno chcesz anulować tę rezerwację?"
                >
                  <template #reference>
                    <button class="btn btn-dark mt-4">Anuluj rezerwację</button>
                  </template>
                </el-popconfirm>
              </div>
            </div>

            <div class="progress-container" v-if="rewardReserved && reward.reservation.status === 'collected'">
              <div class="col-12 text-center">
                <h6>Nagroda została odebrana.</h6>
                <a
                    class="btn btn-dark mt-4"
                    href="/rewards"
                >
                  Lista nagród
                </a>
              </div>
            </div>

            <div class="progress-container" v-if="reward.reservation && reward.reservation.status === 'expired'">
              <div class="col-12 text-center">
                <h6>Nagroda nie została odebrana w terminie.</h6>
                <a
                    class="btn btn-dark mt-4"
                    href="/rewards"
                >
                  Lista nagród
                </a>
              </div>
            </div>

            <div class="progress-description" v-html="reward.description"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import api from "@/api";
import RewardListingItem from "@/components/listing-items/RewardListingItem";
import {ElNotification} from "element-plus";

export default {
  name: "Reward",
  components: {RewardListingItem},
  data() {
    return {
      reward: null,
      error: null,
      processingRewardReservation: false
    }
  },
  created() {
    this.loadRewardDetails();
  },
  computed: {
    collectedPoints() {
      return parseInt(this.$store.state.loggedUser && this.$store.state.loggedUser.loyalty_points && this.$store.state.loggedUser.loyalty_points.available ? this.$store.state.loggedUser.loyalty_points.available : 0);
    },
    enoughPoints() {
      return (this.collectedPoints >= this.reward.points_needed);
    },
    hasReservation() {
      return !!(this.reward.reservation ? this.reward.reservation : false);
    },
    rewardReserved() {
      return !!(
          this.reward.reservation
              ? (this.reward.reservation && this.reward.reservation.status === 'reserved')
              : false
      );
    },
    progressWidth() {
      if (!this.enoughPoints) {
        return Math.round((this.collectedPoints / this.reward.points_needed) * 100) + '%';
      }

      return '100%';
    },
    reservationDisabled() {
      return !(
          this.reward !== null
          &&
          (!this.hasReservation || !this.rewardReserved)
          &&
          this.processingRewardReservation === false
          &&
          (this.reward && this.reward.points_needed ? this.reward.points_needed : 0) <= this.collectedPoints
      );
    },
    reservationDate() {
      let MonthName = new Array(12)
      let limitDate = this.reward && this.reward.issueReceiptDate ? this.reward.issueReceiptDate : null;
      MonthName[1] = "stycznia ";
      MonthName[2] = "lutego ";
      MonthName[3] = "marca ";
      MonthName[4] = "kwietnia ";
      MonthName[5] = "maja ";
      MonthName[6] = "czerwca ";
      MonthName[7] = "lipca ";
      MonthName[8] = "sierpnia ";
      MonthName[9] = "września ";
      MonthName[10] = "października ";
      MonthName[11] = "listopada ";
      MonthName[12] = "grudnia ";

      if (limitDate) {
        return (limitDate.d + ' ' + MonthName[limitDate.m] + ' ' + limitDate.y);
      }

      return '';
    },
    buttonLabel() {
      let label = '';

      if (!this.reservationDisabled) {
        label = 'ZAREZERWUJ TĘ NAGRODĘ';
      } else if (this.reservationDisabled && this.processingRewardReservation) {
        label = 'PRZETWARZANIE REZERWACJI...';
      } else if (this.reservationDisabled && (this.reward && this.reward.reservation ? this.reward.reservation : null) !== null) {
        if (this.reward &&  this.reward.reservation && this.reward.reservation.status === 'collected') {
          label = 'NAGRODA ODEBRANA';
        } else {
          label = 'NAGRODA ZAREZERWOWANA';
        }
      } else if (this.reservationDisabled && (this.reward && this.reward.points_needed ? this.reward.points_needed : 0) > this.collectedPoints) {
        label = 'ZA MAŁO PUNKTÓW';
      }

      return label;
    }
  },
  methods: {
    loadRewardDetails() {
      api.getPromotionalReward(this.$route.params.uuid).then(
        (response) => {
          this.reward = response.data;
        }
      )
    },
    reloadUserData() {
      api.getLoggedIn().then(
          (response) => {
            this.$store.commit('SET_USER', response.data)
          }
      );
    },
    rewardReservation() {
      if (!this.reservationDisabled && (this.reward ? this.reward.uuid : false)) {
        this.processingRewardReservation = true;
        this.error = null;

        api.promotionalRewardReservation(this.reward ? this.reward.uuid : null, this.reward && this.reward.promotionalEventUuid ? this.reward.promotionalEventUuid : null).then(
            () => {
              this.processingRewardReservation = false;
              this.loadRewardDetails();
              this.reloadUserData();
            }
        ).catch(error => {
          this.processingRewardReservation = false;
          let err = error.response.data;

          if (err && err.errors && err.errors['reward']) {
            ElNotification.warning({
              title: err.errors['reward'][0],
            })
          }
        });
      }
    },
    cancelReservation() {
      if ((this.reward && this.reward.uuid) ?? false) {
        this.processingRewardReservation = true;

        api.cancelPromotionalRewardReservation(this.reward.uuid).then(
            () => {
              this.processingRewardReservation = false;
              this.loadRewardDetails();
              this.reloadUserData();
            }
        ).catch(error => {
          this.processingRewardReservation = false;
          let err = error.response.data;

          if (err && err.errors && err.errors['reward']) {
            ElNotification.warning({
              title: err.errors['reward'][0],
            })
          }
        })
      }
    },
    hourText(hour) {
      if (hour) {
        if (hour === 1) {
          return 'godzina';
        } else if ((hour >= 2 && hour <= 4) || (hour >= 21)) {
          return 'godziny';
        }
      }

      return 'godzin';
    },
    minuteText(minutes) {
      if (minutes) {
        if (minutes === 1) {
          return 'minuta';
        } else if (
            (minutes >= 2 && minutes <= 4)
            ||
            (minutes > 20 && ['2', '3', '4'].indexOf(String(minutes).slice(-1)) !== -1)
        ) {
          return 'minuty';
        }
      }

      return 'minut';
    }
  }
}
</script>

<style scoped lang="scss">
.reward-container {
  @extend %view-container-reward;

  .single-reward {
    @extend %view-reward-single;
  }
}
</style>
