import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import globalMixins from './mixins/global'
import dictionary from "./dictionary";
import theme from "./theme";
import ElementPlus from 'element-plus'
import { initSocialIntegrations } from './social'
import { maska } from 'maska';
import './registerServiceWorker'
// import 'element-plus/dist/index.css'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueGtag from "vue-gtag";
import Vue3TouchEvents from "vue3-touch-events";
import axios from 'axios'
import {UniversalSocialauth} from 'universal-social-auth'
import { createI18n } from "vue-i18n";
require('bootstrap')

import pl from "./locales/pl.json";
import en from "./locales/en.json";

// configure i18n
const i18n = createI18n({
    locale: "pl",
    fallbackLocale: "pl",
    messages: { pl, en },
});

// create and start the app
initSocialIntegrations().finally(() => {

    const app = createApp(App)
        .use(router)
        .use(store)
        .use(ElementPlus)
        .use(Vue3TouchEvents)
        .mixin(globalMixins)
        .use(i18n)
        .directive('maska', maska)

    if(process.env.VUE_APP_GTAG) {
        app.use(VueGtag, {
            config: {
                id: process.env.VUE_APP_GTAG,
            }
        })
    }

    Sentry.init({
        app,
        dsn: "https://be4160f70c7f4aa28c57c6bdc3a339de@o347159.ingest.sentry.io/6022977",
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["localhost", "my-site-url.com", /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });

    app.mixin({
        methods: {
            getTranslationsMix: function (text) {

                if (process.env.VUE_APP_ENABLE_TRANSLATIONS_MIX == 1) {
                    return this.$t(text, 'pl') + ' / ' + this.$t(text, 'en');
                } else {
                    return this.$t(text, 'pl')
                }
            },
        },
    })

    const options = {
        providers: {
            google: {
                clientId: '683558405716-tvccihevivcicosvitn1a3hac50stmtc.apps.googleusercontent.com',
                redirectUri: 'https://cp.multiapka-posnania.istest.pl/api/login/google'
            },
        }
    }
    const Oauth = new UniversalSocialauth(axios, options)

    app.config.globalProperties.$dictionary = dictionary
    app.config.globalProperties.$theme = theme
    app.config.globalProperties.$Oauth = Oauth
    app.config.globalProperties.$axios = axios
    app.mount('#app')
})
