<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col">
          <view-title back-location="/" :title="getTranslationsMix($dictionary.form_authorization_register_heading)"></view-title>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <register-form></register-form>
        </div>
      </div>
      <div class="row my-5">
        <div class="col text-center">
          <p><strong>{{ getTranslationsMix($dictionary.register_view_submit_button_prefix) }}</strong></p>
          <router-link to="/login" class="btn btn-outline-dark">{{ getTranslationsMix($dictionary.form_authorization_login_button_label) }}</router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import RegisterForm from "../../components/forms/RegisterForm";
import ViewTitle from "../../components/ViewTitle";
export default {
  name: "Register",
  components: {ViewTitle, RegisterForm}
}
</script>

<style scoped>

</style>
