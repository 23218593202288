<template>
  <div class="listing-item-labels-container" :class="'mode-' + mode">
    <span v-for="(label, index) in labels" :key="index">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "ListingItemLabels",
  props: {
    labels: Array,
    mode: {
      type: String,
      default: 'light'
    }
  }
}
</script>

<style scoped lang="scss">
  .listing-item-labels-container {
    @extend %listing-item-labels-container;
  }
</style>