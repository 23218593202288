<template>
  <main>
    <personal-data-form></personal-data-form>
  </main>
</template>

<script>
import PersonalDataForm from "../../../components/forms/PersonalDataForm";
export default {
  name: "PersonalData",
  components: {PersonalDataForm}
}
</script>

<style scoped>

</style>